<template>
  <v-main>
    <router-view :key="$route.path" class="mainRender" />

    <!-- <dashboard-core-footer /> -->
  </v-main>
</template>
<style scoped>
.mainRender {
  height: 100%;
}
</style>
<script>
import DashboardCoreFooter from "./Footer";
import DashboardCoreAppBar from "./AppBar";
export default {
  name: "DashboardCoreView",
  components: {
    //"dashboard-core-footer": DashboardCoreFooter,
  },
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
  },
  methods: {
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
  },
};
</script>
