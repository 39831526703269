import DashboardIndex from "@/components/LayoutIndex.vue";
import PagesError from "@/views/pages/Error.vue";

const PageRoutes = {
  component: DashboardIndex,
  children: [
    {
      name: "notFound",
      path: "*",
      component: PagesError,
      meta: {
        title: "Not Found",
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
  ],
};

export default PageRoutes;
