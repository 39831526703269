const PartnerB2bRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "partnerb2b",
      path: "/",
      component: () => import("@/views/pages/partnerB2b/PartnerB2b.vue"),
      meta: {
        title: "partnerb2b",
        requiresAuth: true,
        requiresAdmin: true,
        requiredContentAnalyst: true,
      },
    },
    {
      name: "editPartnerb2b",
      path: "/partnerb2b/:id",
      component: () => import("@/views/pages/partnerB2b/EditPartnerB2b.vue"),
      meta: {
        title: "editPartnerb2b",
        requiresAuth: true,
        requiresAdmin: true,
        requiredContentAnalyst: true,
      },
    },
  ],
};

export default PartnerB2bRoutes;
