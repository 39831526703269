const VehicleRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "vehicle",
      path: "/",
      component: () => import("@/views/pages/vehicle/Vehicle.vue"),
      meta: {
        title: "vehicle",
        requiresAuth: true,
        requiresAdmin: true,
        requiredContentAnalyst: true,
      },
    },
    {
      name: "addVehicle",
      path: "/vehicle/add",
      component: () =>
        import("@/views/pages/vehicle/addVehicle/AddVehicle.vue"),
      meta: {
        title: "add Vehicle",
        requiresAuth: true,
        requiresAdmin: true,
        requiredContentAnalyst: true,
      },
    },
    {
      name: "editVehicle",
      path: "/vehicle/edit/:vehicleId",
      props: true,
      component: () =>
        import("@/views/pages/vehicle/addVehicle/AddVehicle.vue"),
      meta: {
        title: "edit Vehicle",
        requiresAuth: true,
        requiresAdmin: true,
        requiredContentAnalyst: true,
      },
    },
    {
      name: "stockTypeHistory",
      path: "/vehicle/:vehicleId/stockType/:stockTypeId/history",
      props: true,
      component: () =>
        import("@/views/pages/vehicle/stockType/StockTypeHistory.vue"),
      meta: {
        title: "StockTypeHistory",
        requiresAuth: true,
        requiresAdmin: true,
        requiredContentAnalyst: true,
      },
    },
  ],
};

export default VehicleRoutes;
