<template>
  <div>
    <v-card
      :width="`${gs.isMobile() ? 250 : 350}px`"
      style="border-radius: 8px"
      class="thesis-card mb-0 mt-3"
    >
      <v-img
        @click="dialog = true"
        v-if="bannerUrl"
        height="150"
        style="border-radius: 8px 8px 0px 0px"
        :src="gs.get_photo_path(bannerUrl)"
      ></v-img>
      <v-avatar
        v-else
        @click="dialog = true"
        tile
        width="100%"
        height="150"
        style="border-radius: 8px 8px 0px 0px !important"
        color="primary"
      ></v-avatar>
      <div
        class="px-3 mt-2 thesis-card-grid"
        :class="{ 'thesis-card-onGrid': Thesis.logo }"
      >
        <div>
          <img
            width="60"
            height="60"
            v-if="Thesis.logo"
            class="thesis-card-logo"
            :src="gs.get_photo_path(Thesis.logo)"
            alt="Logo"
          />
        </div>
        <div>
          <p class="pipeline-card-header dark-color">
            {{ Thesis.name }}
          </p>
          <div
            style="height: 32px"
            class="pipeline-card-text-box"
            :class="[
              !overviewText && gs.isMobile()
                ? 'pipeline-card-text-box-noMargin'
                : '',
            ]"
          >
            <p class="pipeline-card-text">
              {{ overviewText }}
            </p>
          </div>
        </div>
        <v-btn
          @click="redirect_details"
          text
          color="primary"
          class="details-btn"
          >{{ $t("details_click") }}</v-btn
        >
      </div>
    </v-card>
    <v-dialog v-model="dialog" v-if="dialog" scrollable>
      <v-container
        fluid
        tag="section"
        :style="gs.isMobile() && 'background-color: var(--white)'"
      >
        <v-card
          :class="gs.isMobile() ? '' : 'dxa_modal'"
          :style="!gs.isMobile() && 'padding-bottom: 15px'"
        >
          <div class="embed-container">
            <iframe
              :src="`https://player.vimeo.com/video/${gs.check_field(
                Thesis.introVideo
              )}?portrait=0&byline=0&title=0`"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.thesis-card {
  border: 1px solid #9c9c9c;
  box-sizing: border-box;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px;
  padding-bottom: 16px;
  height: 100%;
  background: var(--white);
}

.details-btn {
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.15px;
  text-align: center;
  grid-column: 1 / -1;
}

.details-btn::before {
  background-color: transparent !important;
}

.details-btn:hover {
  color: var(--primary-hover) !important;
}

.details-btn:focus {
  color: var(--primary-focus) !important;
}

.pipeline-card-text-box {
  margin-bottom: 20px;
}

.pipeline-card-text-box-noMargin {
  margin-bottom: 0px;
}

.thesis-card-logo {
  display: block;
  margin: 0;
  padding: 0;
}

.thesis-card-grid {
  display: grid;
}

.thesis-card-onGrid {
  grid-template-columns: 60px 1fr;
  gap: 1rem;
}

@media only screen and (max-width: 769px) {
  .thesis-card {
    min-height: 330px;
    max-height: 100%;
  }

  .details-btn {
    position: absolute;
    bottom: 16px;
  }
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";

export default {
  name: "PipelineThesisCard",

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    dialog: false,
  }),
  components: {},
  props: {
    Thesis: Object,
  },
  async created() {},
  computed: {
    overviewText() {
      let formatOverviewText;
      if (this.Thesis.overview) {
        formatOverviewText = JSON.parse(this.Thesis.overview)[
          this.$i18n.locale
        ];
      } else {
        formatOverviewText = null;
      }

      return formatOverviewText;
    },
    bannerUrl() {
      return JSON.parse(this.Thesis.banner)[this.$i18n.locale];
    },
  },
  methods: {
    redirect_details: function () {
      this.$router.push({
        path: `/theses/details/${this.Thesis.id}`,
      });
    },
    go_to_thesis_page: function () {
      this.$router.push({
        path: `/theses/${this.Thesis.id}`,
      });
    },
  },
};
</script>
