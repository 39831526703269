

const FeedRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "Feed",
      path: "/",
      component: () => import("@/views/pages/feed/Feed.vue"),
      meta: {
        title: "Feed",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: true,
      },
    },
  ],
};

export default FeedRoutes;
