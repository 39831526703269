<template>
  <div class="category-card">
    <a
      @click="Expanded = !Expanded"
      style="display: flex"
      data-test="CompanyCard:Header"
    >
      <h4 v-if="Category.length > 0" class="pipeline-header">
        {{ Title }}
        <span style="color: var(--primary); margin-left: 4px"
          >({{ Category.length }})</span
        >
      </h4>
      <v-spacer></v-spacer>
      <v-icon large color="primary">{{
        Expanded ? "mdi-chevron-up" : "mdi-chevron-down"
      }}</v-icon>
    </a>
    <v-expand-transition>
      <div v-if="Expanded" class="mt-6">
        <div class="company-row">
          <div
            v-for="company in Category"
            :key="company.CompanyId"
            class="company-div"
            :data-companyid="company.CompanyId"
            data-test="CompanyCard:Company"
          >
            <v-card
              :width="`${gs.isMobile() ? 'auto' : '350px'}`"
              class="company-card"
            >
              <v-img
                @click="video_click(company)"
                v-if="company.Banner"
                width="350"
                height="150"
                style="border-radius: 8px"
                class="v-img"
                :src="gs.get_photo_path(company.Banner)"
              ></v-img>
              <v-avatar
                v-else
                @click="video_click(company)"
                style="border-radius: 8px"
                width="350"
                height="150"
                class="v-img"
                color="primary"
              ></v-avatar>
              <div style="height: 125px; display: flex">
                <v-img
                  v-if="company.Logo"
                  max-height="105px"
                  max-width="105px"
                  class="company-logo"
                  contain
                  :src="gs.get_photo_path(company.Logo)"
                />
                <div style="height: 100%; width: 100%" class="px-3 mt-1">
                  <h1 class="company-name" data-test="CompanyCard:CompanyName">
                    {{ company.Name }}
                  </h1>
                  <h6
                    class="company-status"
                    v-if="Title != $t('portfolio_companies')"
                  >
                    <v-avatar class="mb-1 mr-2" color="primary" size="12" />
                    {{ $t("open_offer") }}
                  </h6>
                  <h6 class="company-status" v-else>
                    <v-avatar class="mb-1 mr-2" color="red" size="12" />
                    {{ $t("closed_offer") }}
                  </h6>
                  <p
                    class="company-quota mb-0 mt-1"
                    v-if="company.ReservedQuotas >= 50"
                  >
                    <b
                      id="company-quote-porcentagens"
                      style="color: var(--primary); font-size: 22px"
                      >{{
                        $t("reserved_quotas_briefing", {
                          value: company.ReservedQuotas,
                        }).split("%")[0]
                      }}%</b
                    >{{
                      $t("reserved_quotas_briefing", {
                        value: company.ReservedQuotas,
                      }).split("%")[1]
                    }}
                  </p>
                  <v-row justify="center">
                    <v-btn
                      @click="
                        go_to_company_briefing(
                          company.CompanyId,
                          company.Briefing.Id
                        )
                      "
                      text
                      class="company-profile-btn"
                      color="primary"
                      :data-test="`Alpha:CompanyCard:BtnSeeProfile:${company.CompanyId}`"
                    >
                      {{ $t("see_profile") }}
                    </v-btn>
                  </v-row>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </v-expand-transition>
    <v-dialog v-model="dialog" v-if="dialog">
      <v-container
        fluid
        tag="section"
        :style="gs.isMobile() && 'background-color: var(--white)'"
      >
        <v-card
          :class="gs.isMobile() ? '' : 'dxa_modal'"
          :style="!gs.isMobile() && 'padding-bottom: 15px'"
        >
          <div class="embed-container">
            <iframe
              :src="`https://player.vimeo.com/video/${selectedVideo}?portrait=0&byline=0&title=0`"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.company-div {
  @media only screen and (max-width: 600px) {
    width: -webkit-fill-available;
  }
  @media only screen and (min-width: 600px) {
    margin-right: 28px;
  }
}

.company-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
}

@media only screen and (max-width: 600px) {
  .v-img {
    width: 100% !important;
  }
}

.category-card {
  margin-bottom: 12px;
  background: var(--white);
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 18px 30px 18px 44px;
}
.company-name {
  @media only screen and (max-width: 600px) {
    font-weight: 400 !important;
  }

  color: var(--dark);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  font-family: "Source Sans Pro";
}
.company-status {
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}
.company-quota {
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}
.company-profile-btn {
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.15000000596046448px;
  text-align: center;
}
.company-profile-btn::before {
  background-color: transparent !important;
}
.company-profile-btn:hover {
  color: var(--primary-hover) !important;
}
.company-profile-btn:focus {
  color: var(--primary-focus) !important;
}
.company-logo {
  border-radius: 10px !important;
  object-fit: contain;
  margin-left: 12px;
  margin-top: -12px;
}
.company-card {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border: 1px solid #9c9c9c;
  box-sizing: border-box;
  box-shadow: none !important;
  border-radius: 10px;
  background: var(--white);
}
@media only screen and (max-width: 1450px) {
  .description {
    margin-bottom: 60px !important;
  }
  .bottom-spacer {
    display: none;
  }
}

.description::-webkit-scrollbar {
  display: none;
}

.PipelineDivider {
  margin: 20px;
}

.PipelineCard {
  margin-left: auto;
  margin-right: auto;
}

.company-status {
  color: #9c9c9c;
  font-size: 13px;
  margin-top: auto;
  margin-bottom: auto;
}

@media only screen and (max-width: 360px) {
  #company-quote-porcentagens {
    font-size: 14px !important;
  }

  .company-name {
    font-size: 20px;
  }

  .company-quota {
    font-size: 14px;
  }
}

@import "@/sass/briefing.scss";
.Pipeline-title {
  word-break: break-word;
  white-space: normal;
  line-height: 1.4 !important;
}
.div_card {
  position: absolute;
  bottom: 0;
  // ==width: 75%;
}
.full_width_button {
  margin-left: 3%;
  margin-right: auto;
  width: 90%;
}
.description {
  margin-bottom: 40px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  max-height: 120px;
  overflow: scroll;
}

.v-card.v-sheet.theme--light {
  -webkit-box-shadow: 1px 1px 3px 0px rgba(84, 77, 84, 1);
  -moz-box-shadow: 1px 1px 3px 0px rgba(84, 77, 84, 1);
  box-shadow: 1px 1px 3px 0px rgba(84, 77, 84, 1);
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";

export default {
  name: "PipelineCompanyCard",

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    selectedVideo: null,
    dialog: false,
    companies: [],
  }),
  components: {},
  props: {
    Category: Array,
    Title: String,
    HideButton: {
      default: false,
      type: Boolean,
    },
    Expanded: Boolean,
  },
  async created() {},
  computed: {},
  methods: {
    video_click(company) {
      if (
        company.Briefing.IntroVideo[this.$i18n.locale] != null &&
        company.Briefing.IntroVideo[this.$i18n.locale] != ""
      ) {
        this.selectedVideo = company.Briefing.IntroVideo[this.$i18n.locale];
        this.dialog = true;
      }
    },
    go_to_company_briefing: function (company_id, briefing_id) {
      if (this.Title == this.$t("portfolio_companies")) {
        let type = "profile";
        this.$router.push({
          path: `companies/briefing/${company_id}/${type}`,
          params: { company_id: company_id, type: type },
        });
      } else {
        this.$router.push({
          path: `/companies/${company_id}/briefing/${briefing_id}`,
        });
      }
    },
  },
};
</script>
