export const mainTheme = {
  primary: "#1A56DB",
  // se atentar, que esses dois estilos, hover e focus tem que estar em contraste com a cor primária.
  "primary-hover": "#1E429F",
  "primary-focus": "#76A9FA",
  secondary: "#4B5563",
  dark: "#111928",
  white: "#ffffff",

  // 01d181 => novo tom de verde, retirar esse cara tbm
  // CORES PARA TESTE
  // primary: "#7733FF",
  // secondary: "#197DFF",
  // "primary-hover": "#01D181",
  // "primary-focus": "#03a768",
  // accent: "#283618",
  // info: "#dda15e",
  // offwhite: "#fb8500",
};
