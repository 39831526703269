const VehicleRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "manager",
      path: "/",
      component: () => import("@/views/pages/manager/Manager.vue"),
      meta: {
        title: "manager",
        requiresAuth: true,
        requiresAdmin: true,
        requiredContentAnalyst: true,
      },
    },
    {
      name: "managerPartner",
      path: "/managerPartner",
      component: () =>
        import("@/views/pages/manager/components/ManagerPartner.vue"),
      meta: {
        title: "managerPartner",
        requiresAuth: true,
        requiresAdmin: true,
        requiredContentAnalyst: true,
      },
    },
  ],
};

export default VehicleRoutes;
