<template>
  <v-app>
    <dashboard-core-app-bar
      v-model="expandOnHover"
      @open_onboarding="onboardingDialog = true"
      v-if="accessVueNavigation"
    />

    <dashboard-core-drawer
      :expand-on-hover.sync="expandOnHover"
      v-if="accessVueNavigation"
    />

    <dashboard-core-view />

    <v-dialog v-if="onboardingDialog" v-model="onboardingDialog">
      <OnboardingModal @close="onboardingDialog = false"></OnboardingModal>
    </v-dialog>
    <!-- <dashboard-core-footer /> -->
  </v-app>
</template>

<script>
import DashboardCoreDrawer from "./core/Drawer";
import DashboardCoreView from "./core/View";
import DashboardCoreAppBar from "./core/AppBar";
import DashboardCoreFooter from "./core/Footer";
import OnboardingModal from "./OnboardingModal";
import ApiService from "@/services/ApiService";
import Vue from "vue";

export default {
  name: "DashboardIndex",

  components: {
    "dashboard-core-drawer": DashboardCoreDrawer,
    "dashboard-core-view": DashboardCoreView,
    "dashboard-core-app-bar": DashboardCoreAppBar,
    OnboardingModal,
  },

  created() {
    var type = JSON.parse(localStorage.getItem("user")).type;
    const isRedirectByReactApp = localStorage.getItem("reactApp");
    const accessVueNavigationLocal = localStorage.getItem(
      "accessVueNavigation"
    );

    // POR DEFAULT SEMPRE QUE VIER DA APLICAÇÃO REACT VAI REMOVER O ( DRAWER E APP BAR )
    // CASO PRECISE MOSTRAR O ( DRAWER E APP BAR ) É SÓ ADICIONAR UMA PROP NO LOCALSTORAGE
    // EX: accessVueNavigation = true
    if (isRedirectByReactApp && isRedirectByReactApp === "true") {
      if (accessVueNavigationLocal && accessVueNavigationLocal === "true") {
        this.accessVueNavigation = true;
      } else {
        this.accessVueNavigation = false;
      }
    }

    // if (type == 0) {
    //   this.isInvestor = true;
    //   var user = JSON.parse(localStorage.getItem("user"));
    //   if (!user.isOnboarded && user.type == 0) {
    //     this.onboardingDialog = true;
    //     this.apiService
    //       .postRequest(`user/setOnboard`, {
    //         userId: user.id,
    //         isOnboarded: true,
    //       })
    //       .then((resp) => {})
    //       .catch((err) => {});
    //     user.isOnboarded = true;
    //     this.$store.commit("SET_LOGGED_USER", JSON.stringify(user));
    //   }
    // }

    Vue.$globalEvent.$on("openOnboarding", () => {
      //this.onboardingDialog = true;
    });
  },

  data: () => ({
    expandOnHover: true,
    onboardingDialog: false,
    apiService: new ApiService(),
    accessVueNavigation: true,
  }),
};
</script>
