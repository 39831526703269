const MaterialsB2BRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "MaterialsB2B",
      path: "/b2b",
      component: () => import("./MaterialsB2B.vue"),
      meta: {
        title: "MaterialsB2B",
        requiresAuth: true,
        requiresAdmin: true,
        requiredContentAnalyst: true,
      },
    },
    {
      name: "MaterialsB2BPartnerOrBanker",
      path: "/b2b/content",
      component: () => import("./MaterialsB2BPartnerOrBanker.vue"),
      meta: {
        title: "MaterialsB2BPartnerOrBanker",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: true,
      },
    },
  ],
};

export default MaterialsB2BRoutes;
