

const SettingsRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "Settings",
      path: "/",
      component: () => import("@/views/pages/settings/Settings.vue"),
      meta: {
        title: "Settings",
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
  ],
};

export default SettingsRoutes;
