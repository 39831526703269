import ApiClient from "../../infra/ApiClient";
const { stringifyUrl } = require("query-string");

export default class ApiService {
  constructor() {
    this.apiClient = new ApiClient();
  }

  async postRequest(route, data, showError = true) {
    const resp = await this.apiClient.post(route, data, null, showError);
    return resp;
  }

  async putRequest(route, data) {
    const resp = await this.apiClient.put(route, data);
    return resp;
  }

  async deleteRequest(route, data) {
    const resp = await this.apiClient.delete(route, data);
    return resp;
  }

  async getRequest(route, query = null, showError = true) {
    if (query) {
      route = route + '?' + this.serialize(query);
    }
    const resp = await this.apiClient.get(route, showError);
    return resp;
  }

  async getTestUrl() {
    var query = {};
    const requestURL = stringifyUrl({
      url: `User`,
      query: query,
    });
    const resp = await this.apiClient.get(requestURL);
    return resp;
  }

  serialize(obj) {
    var str = [];
    for (var p in obj) {
      if (obj[p] != null) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return str.join("&");
  }
}
