<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :key="expandOnHover"
    :mini-variant.sync="mini"
    :right="$vuetify.rtl"
    class="dxa-navigation-drawer"
    mobile-breakpoint="960"
    app
    mini-variant-width="100"
    width="260"
    v-bind="$attrs"
    color="dark"
  >
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title
          class="text-uppercase font-weight-regular display-2"
          style="display: flex"
        >
          <div
            v-if="!check_partner({ requiresPartner: true })"
            style="max-width: 170px"
          >
            <img
              v-if="!mini"
              style="width: 100%; max-width: 170px"
              src="../../assets/dealboard-branca.png"
              alt="Dealboard Logo"
            />
            <img
              v-else
              class="navigationLogo"
              src="../../assets/dealboard-square-white.svg"
              alt="Dealboard Logo"
            />
          </div>
          <div v-else>
            <img
              v-if="!mini"
              style="width: 100%; max-width: 170px"
              src="../../assets/dealboard-branca.png"
              alt="Dealboard Logo"
            />
            <img
              v-else
              class="navigationLogo"
              src="../../assets/dealboard-square-white.svg"
              alt="Dealboard Logo"
            />
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-1" />

    <v-list expand nav>
      <div />
      <v-list>
        <v-list-item
          v-for="(item, i) in routesList"
          :key="`item-${i}`"
          link
          :to="item.to"
          :data-test="`Drawer:${item.title}`"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white-color">{{
              $tc(item.title, 2)
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";
import ApiService from "@/services/ApiService";
import { UserTypeEnum } from "@/shared/enums/UserType";

export default {
  name: "DashboardCoreDrawer",

  props: {},

  data: () => ({
    apiService: new ApiService(),
    mini: true,
    expandOnHover: true,
    routesList: null,
    userTypeEnum: UserTypeEnum,
  }),
  created() {
    this.getRoutes();

    if (this.isMobile()) {
      this.mini = false;
      this.expandOnHover = false;
    }
  },
  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    changeLogo: {
      get() {
        return this.$store.state.changeImage;
      },
    },
  },

  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },
    mini(val) {},
  },

  methods: {
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
    changeImage() {},

    check_partner: function (item) {
      var user = JSON.parse(localStorage.getItem("user"));
      var is_partner = user.type === this.userTypeEnum.Partner;
      if (!item.requiresPartner && is_partner) {
        return false;
      } else if (
        (item.requiresPartner && is_partner) ||
        item.requiresPartner === false
      ) {
        return true;
      } else {
        return false;
      }
    },

    getRoutes() {
      this.apiService
        .getRequest("route/allowed")
        .then((resp) => {
          this.routesList = resp.content.filter(
            (item) =>
              item.to != "/round" || process.env.VUE_APP_ROUNDS === "TRUE"
          );
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.v-image.v-responsive.theme--dark {
  width: 30px;
}
.v-list-item__title.text-uppercase.font-weight-regular.display-2 {
  display: flex;
}
.navigationTitle {
  padding-top: 4%;
  font-size: 20px;
}
.navigationLogo {
  width: 45px;
  margin-right: 6px;
  padding-left: 4px;
}
</style>
