

const CompanyRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "ThesesTable",
      path: "/theses",
      component: () => import("@/views/pages/thesis/table/ThesesTable.vue"),
      meta: {
        title: "ThesesTable",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: false,
      },
    },
    {
      name: "AddThesis",
      path: "/theses/add",
      component: () => import("@/views/pages/thesis/form/ManageThesis.vue"),
      meta: {
        title: "AddThesis",
        requiresAuth: true,
        requiredContentAnalyst: true,
        requiresAdmin: true,
        requiresPartner: false,
      },
    },
    {
      name: "EditThesis",
      path: "/theses/edit/:thesis_id",
      component: () => import("@/views/pages/thesis/form/ManageThesis.vue"),
      props: true,
      meta: {
        title: "EditThesis",
        requiresAuth: true,
        requiredContentAnalyst: true,
        requiresAdmin: true,
        requiresPartner: false,
      },
    },
    {
      name: "ThesisDetails",
      path: "/theses/details/:thesis_id",
      component: () => import("@/views/pages/thesis/details/Details.vue"),
      props: true,
      meta: {
        title: "ThesisDetails",
        requiresAuth: true,
        requiredContentAnalyst: false,
        requiresAdmin: false,
        requiresPartner: false,
      },
    },
  ],
};

export default CompanyRoutes;
