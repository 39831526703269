

const RecordEditRegisterRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "RecordEditRegister",
      path: "/",
      component: () => import("./RecordEditRegister"),
      meta: {
        title: "RecordEditRegister",
        requiresAuth: true,
        requiresAdmin: true,
        requiresPartner: false,
      },
    },
  ],
};

export default RecordEditRegisterRoutes;
