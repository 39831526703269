<template>
  <v-btn
    class="teste"
    :loading="loading"
    :disabled="disabled"
    :id="outline ? 'new-dxa-button-outline' : 'new-dxa-button'"
    @click="handleClick"
    >{{ title }}</v-btn
  >
</template>

<script>
export default {
  name: "NewDxaButton",
  props: {
    title: String,
    loading: Boolean,
    outline: Boolean,
    disabled: Boolean,
  },
  methods: {
    handleClick() {
      this.$emit("btnFunction");
    },
  },
};
</script>

<style>
/* solid button */
#new-dxa-button {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--white);
  display: grid;
  place-items: center;
  width: 100%;
  min-height: 44px;

  padding: 8px 20px;
  background-color: var(--primary);
  border-radius: 8px;
  transition: 0.3s;
}

#new-dxa-button:hover {
  background: var(--primary);
  color: var(--dark);
}

/* outline button */
#new-dxa-button-outline {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--primary);
  display: grid;
  place-items: center;
  width: 100%;
  min-height: 44px;

  padding: 8px 20px;
  background-color: transparent;
  border-radius: 8px;
  transition: 0.3s;
  border: 2px solid var(--primary);
}

#new-dxa-button-outline:hover {
  background: var(--primary);
  color: var(--dark);
  border-color: var(--primary);
}

#new-dxa-button-outline:disabled {
  /*  utilizando important para sobrescrever os estilos de disable do vuetify  */
  background: transparent !important;
  font-weight: bold !important;
  color: #afafaf !important;
  border-color: #9c9c9c;
}
</style>
