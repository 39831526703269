<template>
  <div
    style="
      background-color: var(--white);
      z-index: 2;
      min-width: 400px;
      max-width: 500px;
      height: 100%;
      overflow: hidden;
    "
  >
    <div class="conversation">
      <div class="pa-3">
        <h2 style="color: var(--primary)">{{ $tc("notification", 2) }}</h2>
        <div
          class="my-2"
          v-for="n in Notifications.Notifications"
          :key="`item-${n.Id}`"
        >
          <a style="display: flex" @click="redirect(n)">
            <div class="my-auto py-4 px-3">
              <v-row class="mx-0">
                <p
                  class="my-auto"
                  style="margin-bottom: 0px; color: var(--dark)"
                >
                  <strong>{{ gs.convert_date(n.Timestamp, false) }}</strong> -
                  {{ get_notification_text(n) }}
                  <span class="cta">{{ get_cta_text(n) }}</span>
                </p>
              </v-row>
              <v-row align="center" class="mx-0">
                <v-spacer></v-spacer>
                <div
                  style="display: inline-flex"
                  v-if="n.Type == notificationsTypeEnum.ALLOCATION_REQUEST"
                >
                  <a
                    style="display: contents; height: 100%; width: 100%"
                    @click="respond(n, 1)"
                  >
                    <div
                      class="menu_category"
                      style="border: 1px solid var(--primary)"
                    >
                      <p class="mx-2 my-1">ACEITAR</p>
                    </div>
                  </a>
                  <a
                    style="display: contents; height: 100%; width: 100%"
                    @click="respond(n, 0)"
                  >
                    <div
                      class="menu_category"
                      style="color: gray; border: 1px solid gray"
                    >
                      <p class="ttCommonsBold mx-2 my-1">NEGAR</p>
                    </div>
                  </a>
                </div>
              </v-row>
            </div>
          </a>
          <hr class="linha" />
        </div>
        <v-row
          justify="center"
          v-if="Notifications.Pager.CurrentPage < Notifications.Pager.EndPage"
        >
          <v-btn
            text
            color="primary"
            height="15"
            @click="$emit('load_more')"
            :loading="Loading"
          >
            <span style="text-transform: none">{{ $t("see_more") }}</span>
          </v-btn>
        </v-row>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.conversation {
  max-height: 200px;
  overflow-y: auto;
}
.menu_category {
  text-transform: lowercase;
  transition: 0.4s ease-in-out;
  box-sizing: border-box;
  max-width: 80px;
  line-height: 0.8;
  max-height: 35px;
  font-size: 12px;
  text-align: center;
  background-color: var(--white);
  margin-bottom: 4px;
  margin-right: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  div {
    margin-top: 4px;
  }
}

.cta {
  color: var(--primary);
  font-weight: 900;
  text-decoration-line: underline;
}

.linha {
  border: 1px solid;
  color: #aaaaaa;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";
import { notificationsTypeEnum } from "./NotificationsEnums";

export default {
  name: "Notifications",

  props: {
    Notifications: Object,
    Loading: Boolean,
  },

  async created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.click_sign_feature = process.env.VUE_APP_CLICKSIGN === "ON";
  },

  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    user: null,
    click_sign_feature: null,
    formatCurrency: formatCurrency,
    notificationsTypeEnum: notificationsTypeEnum,
  }),

  methods: {
    get_notification_text(n) {
      if (n.Type == notificationsTypeEnum.ALLOCATION_STATUS_CHANGE) {
        let message = n.Message;
        if (
          n.Message === "allocation_confirmed_noti" &&
          n.Allocation.Currency === CurrencyEnum.USD
        ) {
          message = "allocation_confirmed_noti_us";
        }

        return this.$t(message, {
          data: this.gs.convert_date(n.Timestamp, false),
          value: formatCurrency(n.Allocation.Currency, n.Allocation.Value),
          company: n.Allocation.Company.Name,
        });
      } else if (n.Type == notificationsTypeEnum.ALLOCATION_REQUEST) {
      } else if (n.Type == notificationsTypeEnum.EXTRACT_AVAILABLE) {
        return this.$t(n.Message, {
          company: n.Company.Name,
        });
      } else if (n.Type == notificationsTypeEnum.DOCUMENTS_SIGNATURE_REQUEST) {
        if (n.SignatureNotification) {
          return this.$t(n.Message, {
            value: formatCurrency(
              n.SignatureNotification.CompanyInterest.Currency,
              n.SignatureNotification.CompanyInterest.Value
            ),
            company: n.SignatureNotification.CompanyInterest.Company.Name,
          });
        }
      } else if (n.Type == notificationsTypeEnum.CUSTOM_MSG) {
        let position = n.Message.search("<a>");
        if (position > 0) {
          return n.Message.substring(0, position);
        } else {
          return n.Message;
        }
      }
    },
    redirect(n) {
      if (n.Type == notificationsTypeEnum.ALLOCATION_STATUS_CHANGE) {
        this.$router.push({ path: `/dashboard/investor?allocations=true` });
      } else if (n.Type == notificationsTypeEnum.ALLOCATION_REQUEST) {
      } else if (n.Type == notificationsTypeEnum.EXTRACT_AVAILABLE) {
        this.$router.push({ path: `/companies/${n.CompanyId}?tab=3` });
      } else if (n.Type == notificationsTypeEnum.DOCUMENTS_SIGNATURE_REQUEST) {
        this.$router.push("/signature-notifications/received");
        // this.$router.push(
        //   `/companies/briefing/${n.SignatureNotification.CompanyInterest.CompanyId}?notification=${n.SignatureNotification.Id}`
        // );
      } else if (n.Type == notificationsTypeEnum.CUSTOM_MSG) {
        let position = n.Message.search("<a>");
        let position2 = n.Message.search("</a>");
        if (position > 0 && position2 > 0) {
          window.open(n.Message.substring(position + 3, position2), "_blank");
        }
      }
    },
    respond(n) {},

    get_cta_text(n) {
      if (n.Type == notificationsTypeEnum.ALLOCATION_STATUS_CHANGE) {
        if (n.Message === "allocation_confirmed_noti") {
          if (this.click_sign_feature) {
            // foi preciso abrir outro if para verificar se a mensagem é de alocação confirmada e se for estrangeiro
            // a mensagem do link precisa mudar, por isso a necessidade de fazer outra verificação
            if (n.Allocation.Currency === CurrencyEnum.BRL) {
              return this.$t("sign_docs");
            } else {
              return this.$t("details_click");
            }
          }
        }
      } else if (n.Type == notificationsTypeEnum.CUSTOM_MSG) {
        let position = n.Message.search("<a>");
        let position2 = n.Message.search("</a>");
        return n.Message.substring(position + 3, position2);
      } else {
        return this.$t("details_docs");
      }
      return "";
    },
  },
};
</script>
