import ApiService from "../../services/ApiService";

const apiService = new ApiService();

export async function getUserProfile(profileToRouter = false) {
  let user = null;

  await apiService
    .getRequest(`user/user-profile?router=${profileToRouter}`)
    .then((resp) => {
      user = resp.content;
    })
    .catch((err) => {});

  return user;
}
