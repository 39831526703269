const UserRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "users",
      path: "/users",
      component: () => import("@/views/pages/user/index/table/UsersTable.vue"),
      meta: {
        title: "Users",
        requiresAuth: true,
        requiresAdmin: true,
        requiresPartner: false,
      },
    },
    {
      name: "AlphaInvestorTable",
      path: "/alpha-investors",
      component: () => import("@/views/pages/user/alphaInvestor/table/AlphaInvestorTable"),
      meta: {
        title: "AlphaInvestorTable",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: false,
      },
    },
    {
      name: "ManageAlphaInvestor",
      path: "/alpha-investors/edit/:userId",
      component: () => import("@/views/pages/user/alphaInvestor/form/ManageAlphaInvestor"),
      meta: {
        title: "ManageAlphaInvestor",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: false,
      },
    },
    {
      name: "edit_users",
      path: "/users/edit/:user_id",
      component: () => import("@/views/pages/user/index/form/AddUser.vue"),
      meta: {
        title: "Edit User",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: false,
      },
    },
    {
      name: "add_users",
      path: "/users/add",
      component: () => import("@/views/pages/user/index/form/AddUser.vue"),
      meta: {
        title: "Add User",
        requiresAuth: true,
        requiresAdmin: true,
        requiresPartner: false,
      },
    },
    {
      name: "partner_investors",
      path: "/:partnerId/investors",
      component: () => import("@/views/pages/user/users/InvitedsTable.vue"),
      meta: {
        title: "Partner Investors",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: true,
      },
    },
    {
      name: "user_info",
      path: "/investors/info/:userId",
      component: () => import("@/views/pages/user/inviteds/UserInfo/UserInfo.vue"),
      meta: {
        title: "User Info",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: true,
      },
    },
    {
      name: "bankerUsers",
      path: "/banker/users",
      component: () => import("@/views/pages/user/users/BankerUsers.vue"),
      meta: {
        title: "BankerUsers",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: false,
        requiresBanker: true,
      },
    },
  ],
};

export default UserRoutes;
