
const TCRoutes = {
  component: () => import("./TC.vue"),
  children: [
    {
      name: "TC",
      path: "/",
      component: () => import("./TC.vue"),
      meta: {
        title: "TC",
        requiresAuth: false,
        requiresAdmin: false,
        requiresPartner: false,
      },
    },
  ],
};

export default TCRoutes;
