

const StockRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "Stock",
      path: "/",
      component: () => import("./table/StockType.vue"),
      meta: {
        title: "Stock",
        requiresAuth: true,
        requiresAdmin: true,
        requiresPartner: false,
      },
    },
  ],
};

export default StockRoutes;
