import Vue from "vue";
import VueI18n from "vue-i18n";

import en from "vuetify/lib/locale/en";
import pt from "vuetify/lib/locale/pt";

Vue.use(VueI18n);

const messages = {
  en: {
    ...require("@/locales/en.json"),
    $vuetify: en,
  },
  pt: {
    ...require("@/locales/pt.json"),
    $vuetify: pt,
  },
};

export default new VueI18n({
  locale:
    localStorage.getItem("lang") || process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  messages,
});
