import DashboardIndex from "@/components/LayoutIndex.vue";
import DashboardPipeline from "@/views/pages/pipeline/Pipeline.vue";

const PipelineRoutes = {
  component: DashboardIndex,
  children: [
    {
      name: "Pipeline",
      path: "/",
      component: DashboardPipeline,
      meta: {
        title: "Pipeline",
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
  ],
};

export default PipelineRoutes;
