

const CategoryRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "CategoryTable",
      path: "/",
      component: () => import("./index/CategoryTable.vue"),
      meta: {
        title: "CategoryTable",
        requiresAuth: true,
        requiresAdmin: true,
        requiresPartner: false,
        requiredContentAnalyst: true,
      },
    },
  ],
};

export default CategoryRoutes;
