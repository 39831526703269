const TranslationRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "Translation",
      path: "/",
      component: () => import("./Translation"),
      meta: {
        title: "Translation",
        requiresAuth: true,
        requiresAdmin: false,
        requiresTranslator: false,
        requiresPartner: false,
      },
    },
  ],
};

export default TranslationRoutes;
