<template>
  <v-app-bar id="app-bar" absolute app flat color="transparent" height="75">
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      v-if="this.$vuetify.breakpoint.smAndDown"
      @click="
        $vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : setValue(value)
      "
      data-test="Investor:AppBar:BtnDrawer"
    >
      <v-icon v-if="value"> mdi-view-quilt </v-icon>

      <v-icon v-else> mdi-dots-vertical </v-icon>
    </v-btn>

    <v-toolbar-title class="font-weight-light" />

    <!-- v-text="$route.meta.title" -->

    <v-spacer />
    <div class="mx-3" />
    <!-- <v-select
          v-model="$i18n.locale"
          :items="langs"
          @change="cbx_changed($event)"
          :label="$t('language')"
          outlined
          dense
        ></v-select> -->
    <!-- <v-btn
      text
      @click="showOnboarding"
      rounded
      color="primary"
      style="
        margin: 5px 15px;
        height: 38px;
        min-width: 35px;
        width: 38px;
        background-color: #01d281 !important;
        padding: 22px !important;
      "
      ><v-icon large color="white" style="margin-left: 3px">
        mdi-help
      </v-icon></v-btn
    > -->
    <v-menu
      style="z-index: 1000"
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-card
          class="btn_lang d-flex"
          v-if="selectedLanguage != null"
          style="width: 125px"
          height="30px"
          min-width="100px"
          v-bind="attrs"
          v-on="on"
        >
          <v-img
            max-width="23px"
            height="23px"
            :src="selectedLanguageAvatar()"
          ></v-img>
          <p class="p_br" v-if="selectedLanguage.value == 'pt'">PORTUGUÊS/BR</p>
          <p class="p_en" v-else>ENGLISH</p>
        </v-card>
      </template>

      <v-list :tile="false" nav style="background-color: var(--white)">
        <v-list-item
          v-for="(l, i) in langs"
          :key="i"
          @click="showChangeLanguageDialog(l)"
        >
          <v-list-item-title
            v-text="l.text"
            style="line-height: 1.4"
            class="dark-color"
          />
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu
      close-on-click
      bottom
      left
      min-width="200"
      id="notifications-menu"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          color="black"
          icon
          v-bind="attrs"
          v-on="on"
          @click="read_notifications"
        >
          <v-badge
            :content="notifications.Unread"
            :value="notifications.Unread"
            color="primary"
            overlap
          >
            <v-icon> mdi-bell </v-icon>
          </v-badge>
        </v-btn>
      </template>
      <Notifications
        @load_more="load_more"
        :Notifications="notifications"
        :Loading="loadingNotifications"
      ></Notifications>
    </v-menu>

    <v-menu
      style="z-index: 1000"
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          icon
          v-bind="attrs"
          v-on="on"
          data-test="Alpha:AppBar:BtnProfileOptions"
        >
          <div v-if="usuario.type == 0 && usuario.profileDone == false">
            <v-icon color="red darken">mdi-account-alert</v-icon>
          </div>
          <v-avatar
            style="height: 38px; min-width: 35px; width: 38px"
            v-else-if="userImage"
          >
            <v-img :src="userImage"></v-img>
          </v-avatar>
          <div v-else>
            <v-icon color="black">mdi-account</v-icon>
          </div>
        </v-btn>
      </template>

      <v-list :tile="false" nav style="background-color: var(--white)">
        <template v-for="(p, i) in profile">
          <v-divider v-if="p.divider" :key="`divider-${i}`" class="mb-2 mt-2" />

          <app-bar-item v-else :key="`item-${i}`" :to="p.to">
            <v-list-item-title
              v-text="p.title"
              style="line-height: 1.4; color: var(--dark)"
              :data-test="`Alpha:AppBar:Btn${i}`"
            />
            <v-list-item-icon
              v-if="
                usuario.type === UserTypeEnum.Investor &&
                usuario.profileDone == false
              "
              style="line-height: 1.4"
            >
              <v-icon v-text="p.icon" color="red darken"></v-icon>
            </v-list-item-icon>
          </app-bar-item>
        </template>
      </v-list>
    </v-menu>
    <v-dialog max-width="400" width="400" v-model="languageConfirmDialog">
      <div class="dialog-confirm-container">
        <div class="dialog-lang-confirm-header">
          <font-awesome-icon
            icon="fa-solid fa-xmark"
            class="dialog-lang-confirm-close"
            @click="languageConfirmDialog = false"
          />
        </div>
        <div class="dialog-lang-content">
          <font-awesome-icon
            icon="fa-solid fa-globe-americas"
            class="dialog-lang-globe-icon"
            @click="languageConfirmDialog = false"
          />
          <span class="dialog-lang-title" v-if="selectedLanguage">
            {{ languageConfirmData[selectedLanguage.value].title }}
          </span>

          <span class="dialog-lang-subtitle" v-if="selectedLanguage">
            {{ languageConfirmData[selectedLanguage.value].subtitle }}
          </span>

          <div class="dialog-lang-button-container">
            <div class="dialog-lang-button">
              <NewDxaButton
                outline
                @btnFunction="closeConfirmLanguageDialog"
                :title="selectedLanguage.value === 'pt' ? 'Não' : 'No'"
                data-test="AppBar:Dialog:Lang:No"
              />
            </div>
            <div class="dialog-lang-button">
              <NewDxaButton
                @btnFunction="confirmChangeLanguage"
                :title="selectedLanguage.value === 'pt' ? 'Sim' : 'Yes'"
                :loading="confirmChangeLanguageLoading"
                data-test="AppBar:Dialog:Lang:Yes"
              />
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";
import Vue from "vue";
import ApiService from "../../services/ApiService";
import Notifications from "@/components/dxa/notifications/Notifications";
import { UserTypeEnum } from "@/shared/enums/UserType";
// Utilities
import { mapState, mapMutations } from "vuex";
import NewDxaButton from "@/components/dxa/Button/NewDxaButton";
import { LanguageEnum } from "@/shared/enums/LanguageEnum";

export default {
  name: "DashboardCoreAppBar",
  data: () => ({
    apiService: new ApiService(),
    usuario: {
      isProfileDone: null,
    },
    messages: 2,
    loadingNotifications: false,
    notificationPage: 1,
    notifications: { Notifications: [], Pager: null },
    langs: [
      {
        text: "English",
        value: "en",
      },
      {
        text: "Português",
        value: "pt",
      },
    ],
    languageConfirmData: {
      pt: {
        title:
          "Você gostaria de mudar o idioma padrão da sua conta para português?",
        subtitle:
          "Isso muda a linguagem das suas notificações de e-mail, conteúdo exibido, assim como a interface da plataforma. Os termos que você assina serão exibidos de acordo com a sua nacionalidade.",
      },
      en: {
        title:
          "Would you like to change your account's default language to english?",
        subtitle:
          "This changes the language of your email notifications, content displayed as well as the platform's interface. The terms you sign will be displayed according to your nationality.",
      },
    },
    selectedLanguage: null,
    userImage: null,
    languageConfirmDialog: false,
    confirmChangeLanguageLoading: false,
    UserTypeEnum,
  }),
  components: {
    Notifications,
    NewDxaButton,
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },
  mounted() {},
  async created() {
    this.get_user_notifications();
    this.apiService
      .getRequest("user/welcome-user-profile")
      .then((resp) => {
        this.usuario = resp.content;
        if (
          this.usuario.type === UserTypeEnum.Investor &&
          this.usuario.isAlpha
        ) {
          this.checkCalendlyStatus(this.usuario);
        }
      })
      .catch((error) => {});

    this.userImage = JSON.parse(localStorage.user).image;
    this.getUserLang();

    Vue.$globalEvent.$on("profileSaved", () => {
      this.apiService
        .getRequest("user/welcome-user-profile")
        .then((resp) => {
          this.usuario = resp.content;
        })
        .catch((error) => {});
    });
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(["drawer", "lang"]),
    profile() {
      return [
        {
          title: this.$t("profile"),
          to: "/profile",
          icon: "mdi-alert-circle-outline",
        },
        { title: this.$t("settings"), to: "/settings" },
        { divider: true, to: "/" },
        { title: this.$t("log_out"), to: "/auth" },
      ];
    },
  },
  watch: {
    lang() {
      this.getUserLang();
    },
  },
  methods: {
    closeConfirmLanguageDialog() {
      this.languageConfirmDialog = false;
      let switchSelectedLanguage = {
        text: this.selectedLanguage.value === "pt" ? "English" : "Português",
        value: this.selectedLanguage.value === "pt" ? "en" : "pt",
      };

      let localStorageLang = window.localStorage.getItem("lang");
      if (
        localStorageLang &&
        localStorageLang !== this.selectedLanguage.value
      ) {
        this.selectedLanguage = switchSelectedLanguage;
      }
    },
    getUserLang() {
      var language = localStorage.lang;
      if (language) {
        var language_obj = this.langs.filter((x) => x.value == language)[0];
        this.selectedLanguage = language_obj;
      } else {
        var language_obj = this.langs.filter(
          (x) => x.value == this.$i18n.locale
        )[0];
        this.selectedLanguage = language_obj;
      }
    },
    confirmChangeLanguage() {
      this.changeLanguage(this.selectedLanguage);
      this.changeUserLanguage();
    },
    async changeUserLanguage() {
      this.confirmChangeLanguageLoading = true;
      await this.apiService
        .putRequest(
          `user/change-language?language=${this.parseCurrentLanguage()}`
        )
        .then(() => {
          this.confirmChangeLanguageLoading = false;
          this.languageConfirmDialog = false;
        })
        .catch(() => {
          this.confirmChangeLanguageLoading = false;
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
    parseCurrentLanguage() {
      return LanguageEnum[this.selectedLanguage.value];
    },
    async load_more() {
      await this.get_user_notifications();
      this.read_notifications();
    },
    checkCalendlyStatus(user) {
      // caso seja o valor abaixo de 200k, não é carteira alpha, então vai pular o calendar
      if (
        !user.calendlyEventUri &&
        user.investValue >= 200000 &&
        process.env.VUE_APP_SKIP_CALENDLY === "FALSE"
      ) {
        this.$router.push("/calendly");
      }
    },
    async read_notifications() {
      if (this.notifications.Notifications.filter((x) => !x.Read).length > 0) {
        this.notifications.Notifications = this.notifications.Notifications.map(
          (x) => {
            if (!x.Read) {
              this.notifications.Unread -= 1;
              x.Read = true;
            }
            return x;
          }
        );
        this.apiService
          .postRequest(`notification/read-all/${this.notificationPage - 1}`)
          .then((resp) => {})
          .catch((error) => {});
      }
    },
    async get_user_notifications() {
      this.loadingNotifications = true;
      await this.apiService
        .getRequest(`notification/user/${this.notificationPage}`)
        .then((resp) => {
          if (!this.notifications.Pager) {
            this.notifications = JSON.parse(resp.message);
          } else {
            var notifications = JSON.parse(resp.message);
            this.notifications.Pager = notifications.Pager;
            this.notifications.Notifications =
              this.notifications.Notifications.concat(
                notifications.Notifications
              );
          }
          this.notificationPage += 1;
          this.loadingNotifications = false;
        })
        .catch((error) => {});
    },
    selectedLanguageAvatar: function () {
      if (this.selectedLanguage.value.toUpperCase() == "PT") {
        return require("../../assets/Brasil-Icon.png");
      } else {
        return require("../../assets/UK-Icon.png");
      }
    },
    setValue(value) {
      this.$emit("input", !value);
      this.$store.commit("SET_CHANGE_IMAGE", value);
    },
    showOnboarding() {
      this.$emit("open_onboarding");
      // Vue.$globalEvent.$emit("openOnboarding");
    },
    changeLanguage: function (l) {
      this.$store.commit("SET_LANGUAGE", l.value);
    },
    showChangeLanguageDialog(language) {
      this.languageConfirmDialog = true;
      this.selectedLanguage = language;
    },
    cbx_changed: function (event) {
      let item = event.target;
    },
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
  },
};
</script>

<style scoped lang="scss">
.v-btn.v-btn--contained.v-btn--rounded.theme--light.v-size--default {
  margin-bottom: 0px !important;
}
.btn_lang {
  text-align: center;
  justify-content: Center;
  align-items: center;
  background-color: var(--white);
  margin: 30px 15px;
}
p {
  margin: 3px;
}
.p_en {
  color: var(--secondary) !important;
}
.p_br {
  color: var(--primary) !important;
  font-size: 12px;
}

.dialog-confirm-container {
  background-color: var(--white);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dialog-lang-confirm-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.dialog-lang-confirm-close {
  cursor: pointer;
}

.dialog-lang-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialog-lang-globe-icon {
  width: 28px;
  height: 28px;
  color: #1a7dff;
}

.dialog-lang-title {
  text-align: center;
  margin-top: 12px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.dialog-lang-subtitle {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-top: 12px;
}

.dialog-lang-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 16px;
  width: 100%;
}

.dialog-lang-button {
  width: 40%;
  height: 44px;
}
</style>
