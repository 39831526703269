

const SignatureNotificationsRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "SentNotifications",
      path: "/signature-notifications/sent",
      component: () => import("./table/SentNotifications"),
      meta: {
        title: "SentNotifications",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: false,
      },
    },
    {
      name: "ReceivedNotifications",
      path: "/signature-notifications/received",
      component: () => import("./table/ReceivedNotifications"),
      meta: {
        title: "ReceivedNotifications",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: false,
      },
    },
  ],
};

export default SignatureNotificationsRoutes;
