import ApiService from "./ApiService";
import axios from "axios";
import vuetify from "../plugins/vuetify";
import moment from "moment";
import i18n from "../i18n";
import Vue from "vue";
const apiService = new ApiService();
export default class GeneralServices {
  constructor() {}
  show_full_name(user) {
    var stringBuilder = "";
    if (user.Name) {
      stringBuilder += user.Name;
    }
    if (user.LastName) {
      stringBuilder += " " + user.LastName;
    }
    return stringBuilder;
  }
  get_photo_path(path) {
    if (path) {
      return path;
    }
    return null;
  }
  show_name(user) {
    var stringBuilder = "";
    if (!user || (!user.Name && !user.name)) {
      return " - ";
    }
    if (user) {
      stringBuilder += user.Name ? user.Name : user.name;
    }
    if (user.LastName || user.lastName) {
      let lastname = user.LastName ? user.LastName : user.lastName;
      stringBuilder += " " + lastname;
    }
    return stringBuilder;
  }
  getLanguage(lang) {
    let arrLang = ["pt", "en", "es"];
    return arrLang[lang];
  }
  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    if (str == null || str === 0) {
      return false;
    }
    return str.indexOf("{") > -1 && str.indexOf("}") > -1;
  }
  async get_partners() {
    var partners;
    await apiService
      .getRequest("partner/list")
      .then((result) => {
        partners = JSON.parse(result);
      })
      .catch((error) => {});
    return partners;
  }

  async GetPartnersB2b() {
    let partners;
    await apiService
      .getRequest("partnerb2b")
      .then((result) => {
        partners = result.content;
      })
      .catch((error) => {});
    return partners;
  }

  fill_chart(companies, chartData) {
    var series, i, date, day, month, year, history_value, investments_length;
    investments_length = companies.length;
    var investments_chart = {
        labels: [],
        datasets: [],
      },
      colors = [
        "#01d281",
        "#7733FF",
        "#197DFF",
        "#ff9900",
        "#ffff00",
        "#ff99ff",
        "#006622",
        "#ff0000",
        "#000000",
      ];
    try {
      var colorCount = 0;
      for (i = 0; i < investments_length; i++) {
        investments_chart.datasets.push({
          label: companies[i].Name,
          backgroundColor: colors[colorCount],
          fill: false,
          borderColor: colors[colorCount],
          data: [],
        });
        colorCount++;
      }
      chartData.forEach((s) => {
        for (i = 0; i < investments_length; i++) {
          history_value = s.filter(
            (x) => x.CompanyId == companies[i].CompanyId
          );
          if (history_value.length > 0) {
            history_value = history_value[0];
            if (history_value.Value) {
              investments_chart.datasets[i].data.push(history_value.Value);
            } else {
              investments_chart.datasets[i].data.push(null);
            }
          } else {
            investments_chart.datasets[i].data.push(null);
          }
        }
        date = new Date(s[0].Date.replace("T", " "));
        day = date.getUTCDate().toString().padStart(2, "0");
        month = (date.getMonth() + 1).toString().padStart(2, "0");
        year = date.getFullYear().toString();
        investments_chart.labels.push(`${day}/${month}/${year}`);
      });
    } catch (err) {}
    return investments_chart;
  }
  format_to_currency(value) {
    var formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formatter.format(value);
  }
  get_status_text(status) {
    var options = [
      "interest_status_pending",
      "interest_status_approved",
      "interest_status_declined",
      "interest_status_waiting_investor",
    ];
    return options[status];
  }
  convert_date(date, includes_time) {
    if (!date) {
      return "-";
    }
    var m_date = moment(date);
    if (includes_time) {
      return m_date.format("DD/MM/YYYY HH:mm");
    }
    return m_date.format("DD/MM/YYYY");
  }
  parse_to_datetime_string(date, format) {
    if (!date) return null;
    return moment(date, format).format("YYYY-MM-DD");
  }
  format_to_currency(value) {
    var formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formatter.format(value);
  }
  async validate_banker(bankerId) {
    var exists = false;
    await apiService
      .getRequest(`banker/validate/${bankerId}`)
      .then((resp) => {
        exists = true;
      })
      .catch((error) => {});
    return exists;
  }
  async get_country_codes() {
    var countries = await apiService
      .getRequest(`health/country-codes`)
      .then((resp) => {
        return JSON.parse(resp.resp);
      })
      .catch((err) => {});
    return countries;
  }
  check_field(field) {
    if (!field) {
      return null;
    }
    if (this.isJson(field)) {
      var converted = JSON.parse(field);
      if (converted[i18n.locale]) {
        return converted[i18n.locale];
      }
      return null;
    } else {
      return field;
    }
  }
  is_valid_date(date) {
    var m_date = moment(date);
    return !(m_date.year() == 1 && m_date.month() == 0 && m_date.date() == 1);
  }

  parseDate(date) {
    if (!date) return null;
    try {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    } catch (err) {
      return null;
    }
  }
  async check_if_document_number_exists(documentNumber, isAlpha) {
    var documentNumberExists = false;
    await apiService
      .getRequest(`user/check-document-number/${documentNumber}`)
      .then((resp) => {})
      .catch(() => {
        documentNumberExists = true;
      });
    return documentNumberExists;
  }
  isMobile() {
    if (vuetify.framework.breakpoint.smAndDown) {
      return true;
    }
    return false;
  }

  copy_to_clipboard(url, success_message) {
    navigator.clipboard.writeText(url).then((resp) => {
      Vue.$toast.success(i18n.messages[i18n.locale][success_message]);
    });
  }

  copy_url_to_clipboard(url) {
    this.copy_to_clipboard(url, "link_copied");
  }

  get_translatable_field(obj, field) {
    if (obj[field] != null) {
      if (this.isJson(obj[field])) {
        obj[field] = JSON.parse(obj[field]);
      } else {
        var text = obj[field];
        obj[field] = {};
        obj[field][i18n.locale] = text;
      }
    } else {
      obj[field] = {};
      obj[field][i18n.locale] = "";
    }
  }
}
