const AlphaManagerRoutes = {
  component: () => import("@/views/pages/BaseLayout"),
  children: [
    {
      path: "",
      name: "AlphaManagerCalendly",
      component: () => import("./Calendly.vue"),
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
    {
      path: "finish",
      name: "FinishCalendly",
      component: () => import("./Finish.vue"),
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
  ],
};

export default AlphaManagerRoutes;
