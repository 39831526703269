import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"id":"app-bar","absolute":"","app":"","flat":"","color":"transparent","height":"75"}},[(this.$vuetify.breakpoint.smAndDown)?_c(VBtn,{staticClass:"mr-3",attrs:{"elevation":"1","fab":"","small":"","data-test":"Investor:AppBar:BtnDrawer"},on:{"click":function($event){_vm.$vuetify.breakpoint.smAndDown ? _vm.setDrawer(!_vm.drawer) : _vm.setValue(_vm.value)}}},[(_vm.value)?_c(VIcon,[_vm._v(" mdi-view-quilt ")]):_c(VIcon,[_vm._v(" mdi-dots-vertical ")])],1):_vm._e(),_c(VToolbarTitle,{staticClass:"font-weight-light"}),_c(VSpacer),_c('div',{staticClass:"mx-3"}),_c(VMenu,{staticStyle:{"z-index":"1000"},attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [(_vm.selectedLanguage != null)?_c(VCard,_vm._g(_vm._b({staticClass:"btn_lang d-flex",staticStyle:{"width":"125px"},attrs:{"height":"30px","min-width":"100px"}},'v-card',attrs,false),on),[_c(VImg,{attrs:{"max-width":"23px","height":"23px","src":_vm.selectedLanguageAvatar()}}),(_vm.selectedLanguage.value == 'pt')?_c('p',{staticClass:"p_br"},[_vm._v("PORTUGUÊS/BR")]):_c('p',{staticClass:"p_en"},[_vm._v("ENGLISH")])],1):_vm._e()]}}])},[_c(VList,{staticStyle:{"background-color":"var(--white)"},attrs:{"tile":false,"nav":""}},_vm._l((_vm.langs),function(l,i){return _c(VListItem,{key:i,on:{"click":function($event){return _vm.showChangeLanguageDialog(l)}}},[_c(VListItemTitle,{staticClass:"dark-color",staticStyle:{"line-height":"1.4"},domProps:{"textContent":_vm._s(l.text)}})],1)}),1)],1),_c(VMenu,{attrs:{"close-on-click":"","bottom":"","left":"","min-width":"200","id":"notifications-menu","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"black","icon":""},on:{"click":_vm.read_notifications}},'v-btn',attrs,false),on),[_c(VBadge,{attrs:{"content":_vm.notifications.Unread,"value":_vm.notifications.Unread,"color":"primary","overlap":""}},[_c(VIcon,[_vm._v(" mdi-bell ")])],1)],1)]}}])},[_c('Notifications',{attrs:{"Notifications":_vm.notifications,"Loading":_vm.loadingNotifications},on:{"load_more":_vm.load_more}})],1),_c(VMenu,{staticStyle:{"z-index":"1000"},attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","icon":"","data-test":"Alpha:AppBar:BtnProfileOptions"}},'v-btn',attrs,false),on),[(_vm.usuario.type == 0 && _vm.usuario.profileDone == false)?_c('div',[_c(VIcon,{attrs:{"color":"red darken"}},[_vm._v("mdi-account-alert")])],1):(_vm.userImage)?_c(VAvatar,{staticStyle:{"height":"38px","min-width":"35px","width":"38px"}},[_c(VImg,{attrs:{"src":_vm.userImage}})],1):_c('div',[_c(VIcon,{attrs:{"color":"black"}},[_vm._v("mdi-account")])],1)],1)]}}])},[_c(VList,{staticStyle:{"background-color":"var(--white)"},attrs:{"tile":false,"nav":""}},[_vm._l((_vm.profile),function(p,i){return [(p.divider)?_c(VDivider,{key:("divider-" + i),staticClass:"mb-2 mt-2"}):_c('app-bar-item',{key:("item-" + i),attrs:{"to":p.to}},[_c(VListItemTitle,{staticStyle:{"line-height":"1.4","color":"var(--dark)"},attrs:{"data-test":("Alpha:AppBar:Btn" + i)},domProps:{"textContent":_vm._s(p.title)}}),(
              _vm.usuario.type === _vm.UserTypeEnum.Investor &&
              _vm.usuario.profileDone == false
            )?_c(VListItemIcon,{staticStyle:{"line-height":"1.4"}},[_c(VIcon,{attrs:{"color":"red darken"},domProps:{"textContent":_vm._s(p.icon)}})],1):_vm._e()],1)]})],2)],1),_c(VDialog,{attrs:{"max-width":"400","width":"400"},model:{value:(_vm.languageConfirmDialog),callback:function ($$v) {_vm.languageConfirmDialog=$$v},expression:"languageConfirmDialog"}},[_c('div',{staticClass:"dialog-confirm-container"},[_c('div',{staticClass:"dialog-lang-confirm-header"},[_c('font-awesome-icon',{staticClass:"dialog-lang-confirm-close",attrs:{"icon":"fa-solid fa-xmark"},on:{"click":function($event){_vm.languageConfirmDialog = false}}})],1),_c('div',{staticClass:"dialog-lang-content"},[_c('font-awesome-icon',{staticClass:"dialog-lang-globe-icon",attrs:{"icon":"fa-solid fa-globe-americas"},on:{"click":function($event){_vm.languageConfirmDialog = false}}}),(_vm.selectedLanguage)?_c('span',{staticClass:"dialog-lang-title"},[_vm._v(" "+_vm._s(_vm.languageConfirmData[_vm.selectedLanguage.value].title)+" ")]):_vm._e(),(_vm.selectedLanguage)?_c('span',{staticClass:"dialog-lang-subtitle"},[_vm._v(" "+_vm._s(_vm.languageConfirmData[_vm.selectedLanguage.value].subtitle)+" ")]):_vm._e(),_c('div',{staticClass:"dialog-lang-button-container"},[_c('div',{staticClass:"dialog-lang-button"},[_c('NewDxaButton',{attrs:{"outline":"","title":_vm.selectedLanguage.value === 'pt' ? 'Não' : 'No',"data-test":"AppBar:Dialog:Lang:No"},on:{"btnFunction":_vm.closeConfirmLanguageDialog}})],1),_c('div',{staticClass:"dialog-lang-button"},[_c('NewDxaButton',{attrs:{"title":_vm.selectedLanguage.value === 'pt' ? 'Sim' : 'Yes',"loading":_vm.confirmChangeLanguageLoading,"data-test":"AppBar:Dialog:Lang:Yes"},on:{"btnFunction":_vm.confirmChangeLanguage}})],1)])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }