const UseTermsRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "UseTerms",
      path: "/use-terms",
      component: () => import("@/views/pages/useTerms/UseTerms.vue"),
      meta: {
        title: "UseTerms",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: false,
      },
    },
  ],
};

export default UseTermsRoutes;
