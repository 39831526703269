
const FileLogRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "FileDownloadLogs",
      path: "/",
      component: () => import( "./FileLog"),
      meta: {
        title: "FileDownloadLogs",
        requiresAuth: true,
        requiresAdmin: true,
        requiresPartner: false,
      },
    },
  ],
};

export default FileLogRoutes;
