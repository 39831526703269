import ApiService from "../../services/ApiService";

const api = new ApiService();

export async function userAccessLog(data) {
  let { routerPath, domain } = data;
  let payload;

  if (routerPath && domain) {
    payload = {
      Route: routerPath,
      Domain: domain,
    };

    await api
      .postRequest(`useraccesslog/route-access`, payload)
      .then((resp) => {})
      // se der um erro na requisição, vai cair no catch, mas não vai refletido para o usuário.
      // pois esse endpoint, caso de erro, não impede o usuário de usar a plataforma.
      .catch((err) => {});
  }
}
