const ProfileRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "Profile",
      path: "/",
      component: () => import("@/views/pages/profile/Profile.vue"),
      meta: {
        title: "Profile",
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
    {
      name: "CompleteProfile",
      path: "/profile/complete",
      component: () => import("@/views/pages/profile/CompleteProfile.vue"),
      meta: {
        title: "CompleteProfile",
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
    {
      name: "CompleteProfileVerifySteps",
      path: "/profile/verify",
      component: () =>
        import("@/views/pages/profile/verify/CompleteProfileVerifySteps.vue"),
      meta: {
        title: "CompleteProfileVerifySteps",
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
  ],
};

export default ProfileRoutes;
