export function redirectMarketApp(routerToPath, openNewWindow, appMarketProps) {
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  const lang = localStorage.getItem("lang");
  const signedNda = localStorage.getItem("signed_nda");
  const expires = localStorage.getItem("expires");
  const reactApp = true;

  let localData = {
    token,
    lang,
    signedNda,
    expires,
    reactApp,
    routerToPath,
    appMarketProps: {},
    fromVueApp: "true",
  };

  // CASO PRECISE PASSAR ALGUMA PROP PARA APLICAÇÃO MARKET, SÓ PREENCHER ESSE OBJETO
  if (appMarketProps) {
    localData.appMarketProps = { ...appMarketProps };
  }

  const objectJson = JSON.stringify(localData);
  const objectJsonToBase64 = Buffer.from(objectJson).toString("base64");

  if (openNewWindow) {
    window.open(
      `${process.env.VUE_APP_REACT_MARKET_PLATFORM_BASE_URL}/appHandler?form_data=${objectJsonToBase64}`,
      "_blank"
    );
  } else {
    window.location.href = `${process.env.VUE_APP_REACT_MARKET_PLATFORM_BASE_URL}/appHandler?form_data=${objectJsonToBase64}`;
  }
}
