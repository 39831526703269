const RoundRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "round",
      path: "/",
      component: () => import("@/views/pages/round/Rounds.vue"),
      meta: {
        title: "round",
        requiresAuth: true,
        requiresAdmin: false,
        requiredContentAnalyst: false,
      },
    },
    {
      name: "new-round",
      path: "new",
      component: () => import("@/views/pages/round/NewRound.vue"),
      meta: {
        title: "new-round",
        requiresAuth: true,
        requiresAdmin: true,
        requiredContentAnalyst: true,
      },
    },
    {
      name: "edit-round",
      path: ":roundId",
      component: () => import("@/views/pages/round/NewRound.vue"),
      meta: {
        title: "edit-round",
        requiresAuth: true,
        requiresAdmin: true,
        requiredContentAnalyst: true,
      },
    },
    {
      name: "new-round-briefing",
      path: "new/briefing/:briefingId",
      component: () =>
        import("@/views/pages/round/components/BriefingComponent.vue"),
      meta: {
        title: "new-round-briefing",
        requiresAuth: true,
        requiresAdmin: true,
        requiredContentAnalyst: true,
      },
    },
    {
      name: "round-permissions",
      path: "permissions/:roundId",
      component: () => import("@/views/pages/round/Permissions.vue"),
      meta: {
        title: "round-permissions",
        requiresAuth: true,
        requiresAdmin: true,
        requiredContentAnalyst: true,
      },
    },
    {
      name: "round-details",
      path: "details/:roundId",
      component: () => import("@/views/pages/round/RoundDetails.vue"),
      meta: {
        title: "round-details",
        requiresAuth: true,
        requiresAdmin: false,
        requiredContentAnalyst: false,
      },
    },
    {
      name: "round-allocations",
      path: "allocations/:roundId",
      component: () => import("@/views/pages/round/RoundAllocations.vue"),
      meta: {
        title: "round-allocations",
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
    {
      name: "round-accesses",
      path: "accesses/:roundId",
      component: () => import("@/views/pages/round/RoundAccesses.vue"),
      meta: {
        title: "round-accesses",
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
  ],
};

export default RoundRoutes;
