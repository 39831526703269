<template>
  <v-alert color="orange" v-if="show_banner">
    <template v-slot:prepend>
      <v-icon color="white" large class="mr-3"
        >mdi-alert-octagon-outline</v-icon
      >
    </template>
    <v-row align="center">
      <v-col cols="12" md="10">
        <div class="textPart my-auto">
          <span
            style="color: var(--white)"
            :style="isMobile() ? 'font-size: 15px;' : 'font-size: 17px;'"
          >
            {{ $t("sign_contract_alert") }}
          </span>
        </div>
      </v-col>
      <v-col cols="12" md="2">
        <div class="buttonPart">
          <v-btn color="white" outlined class="mr-4" @click="openDocusign">
            <span
              :style="isMobile() ? 'font-size: 11px;' : ''"
              class="textButton"
            >
              {{ $tc("sign_contract", 1) }}
            </span>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-alert>
  <!-- <div
    v-if="show_banner"
    class="overallBottom mx-auto"
    :style="drawer && isMobile() ? 'display: none' : ''"
  >

  </div> -->
</template>
<style scoped>
.overallBottom {
  max-width: 100vw;
  position: fixed;
  margin-left: 8vh !important;
  bottom: 10px;
  overflow: auto;
  display: none;
  display: flex;
  width: 86vw;
  justify-content: space-between;
  background-color: #fcfcfc;
  border-left: 3px #59d281 solid;
  z-index: 9999;
  padding-top: 0px;
  -webkit-box-shadow: 0px 0px 1px 1px rgba(133, 130, 133, 1);
  -moz-box-shadow: 0px 0px 1px 1px rgba(133, 130, 133, 1);
  box-shadow: 0px 0px 1px 1px rgba(133, 130, 133, 1);
}
.overallBottom .textPart {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.overallBottom .buttonPart {
  padding: 11px;
}

.buttonPart .v-btn.v-btn--contained.theme--light.v-size--default:hover {
  background-color: #3ec26a;
}

.buttonPart .textButton {
  font-size: 14px;
  margin-bottom: 0px;
}

.buttonPart .v-btn.v-btn--contained.theme--light.v-size--default {
  background-color: #59d281;
}

.overallBottom .textPart p {
  margin-bottom: 0px;
  margin-left: 8px;
  font-size: 25px;
  font-weight: 700;
}
</style>
<script>
export default {
  name: "SignContractBanner",
  components: {},
  data: () => ({
    show_banner: false,
  }),
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
  },
  async created() {
    var userDoc = JSON.parse(localStorage.getItem("user")).signedContract;
    if (!userDoc) {
      this.show_banner = true;
    }
  },
  methods: {
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
    openDocusign() {
      window.open(
        "https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=1b78344b-f199-493a-9f1a-8aa62086d935&env=na2&acct=ceb376f0-454d-4293-8b50-1a17bc98c402&v=2"
      );
    },
  },
};
</script>
