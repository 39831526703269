import { CurrencyEnum } from "../enums/CurrencyEnum";
import { getKeyByValue } from "./objectHelper";

export const formatCurrency = (
  currencyType,
  value,
  minDigits = 2,
  maxDigits = 2
) => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: getKeyByValue(CurrencyEnum, currencyType),
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits > minDigits ? maxDigits : minDigits,
  });

  if (currencyType === CurrencyEnum.USD) {
    return addUSInDollar(formatter.format(value));
  }
  return formatter.format(value);
};

const addUSInDollar = (formattedCurrency) => {
  if (formattedCurrency.includes("-"))  {
    return formattedCurrency.replace("-", "- US");
  }
  return `US${formattedCurrency}`;
};

export const getCurrencyPrefix = (currencyType) => {
  const literal = {
    BRL: "R$",
    USD: "US$",
    GBP: "£",
    EUR: "€",
  };
  return literal[getKeyByValue(CurrencyEnum, currencyType)];
};
