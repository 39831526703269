<template>
  <div>
    <router-view />
    <FlashMessage></FlashMessage>
  </div>
</template>

<style>
@font-face {
  src: url("~@/assets/fonts/NeurialGrotesk-Regular.ttf") format("opentype");
  font-family: "Neurial Grotesk";
}
@font-face {
  src: url("~@/assets/fonts/NeurialGrotesk-Medium.ttf") format("opentype");
  font-family: "Neurial Grotesk Medium";
}
@font-face {
  src: url("~@/assets/fonts/NeurialGrotesk-Bold.ttf") format("opentype");
  font-family: "Neurial Grotesk Bold";
}
@font-face {
  src: url("~@/assets/fonts/Segoe-UI.ttf") format("truetype");
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: normal;
}
@font-face {
  src: url("~@/assets/fonts/SourceSansPro-Regular.ttf");
  font-family: "Source Sans Pro";
}
@font-face {
  src: url("~@/assets/fonts/SourceSansPro-SemiBold.ttf");
  font-family: "Source Sans Pro SemiBold";
}
@font-face {
  src: url("~@/assets/fonts/SourceSansPro-Bold.ttf");
  font-family: "Source Sans Pro Bold";
}
@font-face {
  src: url("~@/assets/fonts/SourceSans3-Regular.ttf");
  font-family: "Source Sans 3";
}
@font-face {
  src: url("~@/assets/fonts/SourceSans3-Light.ttf");
  font-family: "Source Sans 3 Light";
}
.v-btn {
  text-transform: none !important;
}

@media only screen and (max-width: 600px) {
  .Vue-Toastification__container .Vue-Toastification__toast {
    width: 90% !important;
    margin: 15px;
    border-radius: 8px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Source Sans Pro";
  font-weight: normal;
}
</style>

<script>
//para identificar seus usuários você deve chamar esse método passando o nome e o email do usuário e opcionalmente informar o id de seu chat
//lembrando que isso é apenas um modelo teste. Você deve chamar esse método em seu site para efetuar o login. Você pode autenticar seu usuário em qualquer momento no fluxo do seu site.
</script>

<script>
import { mapMutations } from "vuex";
import { UserTypeEnum } from "@/shared/enums/UserType";

export default {
  name: "App",
  data() {
    return {
      UserTypeEnum,
    };
  },
  created() {
    let userLocal = JSON.parse(localStorage.getItem("user"));

    // controlando a prop isManagerUser no vuex
    if (
      userLocal.type === this.UserTypeEnum.ManagerUser ||
      (userLocal.managerPartner && userLocal.managerPartner.id)
    ) {
      this.setIsManagerUser(true);
    } else {
      this.setIsManagerUser(false);
    }

    if (
      window.location.host === process.env.VUE_APP_DXA_HOST_FRONT ||
      window.location.host === process.env.VUE_APP_DEALBOARD_HOST_FRONT
    ) {
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 2078856, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    }
  },
  methods: {
    ...mapMutations({
      setIsManagerUser: "SET_IS_MANAGER_USER",
    }),
    logar_chat(name, email) {
      octadesk.chat.login({
        name: name, // nome
        email: email, // email
      });
    },
  },
};
</script>
