

const CompanyInterestRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "InterestsTable",
      path: "/",
      component: () => import("./table/InterestsTable.vue"),
      meta: {
        title: "InterestsTable",
        requiresAuth: true,
        requiresAdmin: true,
        requiresPartner: false,
      },
    },
    {
      name: "SignContractsPage",
      path: "/requests/sign-contracts/:allocationId",
      component: () => import("./form/SignContracts.vue"),
      meta: {
        title: "SignContractsPage",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: false,
      },
    },
  ],
};

export default CompanyInterestRoutes;
