

const CompanyRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "companies",
      path: "/companies",
      component: () => import("@/views/pages/company/index/table/CompaniesTable.vue"),
      meta: {
        title: "Companies",
        requiresAuth: true,
        requiresAdmin: true,
        requiredContentAnalyst: true,
        requiresPartner: false,
        requiresFinancialAnalyst: true,
      },
    },
    {
      name: "addCompany",
      path: "/companies/add",
      component: () => import("@/views/pages/company/index/formBasic/AddCompany.vue"),
      meta: {
        title: "Add Company",
        requiresAuth: true,
        requiredContentAnalyst: true,
        requiresAdmin: true,
        requiresPartner: false,
      },
    },
    {
      name: "editCompanyBriefing",
      path: "/companies/edit/briefing/:briefingId",
      component: () => import("@/views/pages/company/index/formBriefing/AddCompany.vue"),
      props: true,
      meta: {
        title: "Edit Company Briefing",
        requiresAuth: true,
        requiredContentAnalyst: true,
        requiresAdmin: true,
        requiresPartner: false,
      },
    },
    {
      name: "editCompany",
      path: "/companies/edit/:company_id",
      component: () => import("@/views/pages/company/index/formBasic/AddCompany.vue"),
      props: true,
      meta: {
        title: "Edit Company",
        requiresAuth: true,
        requiredContentAnalyst: true,
        requiresAdmin: true,
        requiresPartner: false,
      },
    },
    {
      name: "CompanyPlaylist",
      path: "/companies/playlist/:company_id",
      props: true,
      component: () => import("@/views/pages/company/playlist/CompanyPlaylist.vue"),
      meta: {
        title: "Details",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: false,
      },
    },
    {
      name: "companyPage",
      path: "/companies/:company_id",
      props: true,
      component: () => import("@/views/pages/company/CompanyPage.vue"),
      meta: {
        title: "Details",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: false,
      },
    },
    // {
    //   name: "companyBriefing",
    //   path: "/companies/briefing/:partner_name/:company_name",
    //   props: true,
    //   component: CompanyBriefing,
    //   meta: {
    //     title: "Briefing",
    //     requiresAuth: true,
    //     requiresAdmin: false,
    //     requiresPartner: false,
    //   },
    // },
    {
      name: "CompanyBriefing",
      path: "/companies/briefing/:company_id",
      props: true,
      component: () => import("@/views/pages/company/briefing/Briefing.vue"),
      meta: {
        title: "CompanyBriefing",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: false,
      },
    },
    {
      name: "CompanyBriefingType",
      path: "/companies/briefing/:company_id/:type",
      props: true,
      component: () => import("@/views/pages/company/briefing/Briefing.vue"),
      meta: {
        title: "CompanyBriefingType",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: false,
      },
    },
    {
      name: "CompanyBriefingPipeline",
      path: "/companies/:company_id/briefing/:briefing_id",
      props: true,
      component: () => import("@/views/pages/company/briefing/Briefing.vue"),
      meta: {
        title: "CompanyBriefingPipeline",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: false,
      },
    },
    {
      name: "AllocationsFollowUp",
      path: "/companies/allocations/:companyId",
      props: true,
      component: () => import("@/views/pages/company/allocations/table/AllocationsFollowUp.vue"),
      meta: {
        title: "AllocationsFollowUp",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: false,
      },
    },
  ],
};

export default CompanyRoutes;
