

const LogExtratoRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "LogExtrato",
      path: "/",
      component: () => import("./LogExtrato"),
      meta: {
        title: "LogExtrato",
        requiresAuth: true,
        requiresAdmin: true,
        requiresPartner: false,
      },
    },
  ],
};

export default LogExtratoRoutes;
