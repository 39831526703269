

const BankerRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "banker_table",
      path: "/banker/table",
      component: () => import("@/views/pages/banker/table/BankersTable.vue"),
      meta: {
        title: "BankersTable",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: true,
      },
    },
    {
      name: "edit_banker",
      path: "/banker/edit/:user_id",
      component: () => import("@/views/pages/banker/form/AddBanker.vue"),
      meta: {
        title: "EditBanker",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: true,
      },
    },
    {
      name: "add_banker",
      path: "/banker/add",
      component: () => import("@/views/pages/banker/form/AddBanker.vue"),
      meta: {
        title: "Add Banker",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: true,
      },
    },
  ],
};

export default BankerRoutes;
