export const UserTypeEnum = {
  Investor: 0,
  Admin: 1,
  Partner: 2,
  Translator: 3,
  FinancialAnalyst: 4,
  ContentAnalyst: 5,
  Banker: 6,
  AlphaAnalyst: 7,
  ManagerUser: 8,
};
