const Auth = () => import("./Auth.vue");
const Login = () => import("./login/Login.vue");
const AlphaRegister = () => import("./register/alpha/portfolio/Register.vue");
const AlphaUserRegister = () => import("./register/alpha/user/Register.vue");
const AccountValidation = () =>
  import("./register/alpha/user/AccountValidation.vue");
const Register = () => import("./register/Register.vue");
const ResetPassword = () => import("./resetPassword/ResetPassword.vue");
const NewPassword = () => import("./newPassword/NewPassword.vue");
const NewPassword2 = () => import("./resetPassword/steps/NewPassword.vue");
const AlphaWelcomePage = () => import("./register/alpha/user/WelcomePage.vue");
const BankerWelcomePage = () => import("./register/banker/WelcomePage.vue");
const BankerRegister = () => import("./register/banker/Register.vue");
const BankerFinishPage = () => import("./register/banker/FinishPage.vue");
const BankerOnboarding = () => import("./onboarding/Banker/Onboarding.vue");

const AuthRoutes = {
  component: () => import("@/views/pages/Index"),
  children: [
    {
      path: "",
      name: "LoginClient",
      component: Login,
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: "forgot-password",
      name: "ForgotPasswordClient",
      component: ResetPassword,
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: "welcome-page",
      name: "WelcomePage",
      component: AlphaWelcomePage,
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: "alpha-register-client-dxa",
      redirect: "welcome-page",
    },
    {
      path: "banker-welcome-page",
      name: "BankerWelcomePage",
      component: BankerWelcomePage,
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: "banker-register-finish",
      name: "BankerFinishPage",
      component: BankerFinishPage,
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: "banker-register",
      name: "BankerRegister",
      component: BankerRegister,
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: "banker-onboarding",
      name: "BankerOnboarding",
      component: BankerOnboarding,
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
    {
      path: "register",
      name: "RegisterClient",
      component: Register,
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
      },
      children: [{ path: "*", component: Register }],
    },
    {
      path: "register-client",
      name: "AlphaRegisterClient",
      component: AlphaRegister,
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: "alpha-account-validation",
      name: "AccountValidation",
      component: AccountValidation,
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: "new-password",
      name: "NewPasswordClient",
      component: NewPassword,
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: "new-password2",
      name: "NewPasswordClient2",
      component: NewPassword2,
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    { path: "*", redirect: "/auth" },
  ],
};

export default AuthRoutes;
