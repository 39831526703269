

const DashboardRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "investor_dashboard",
      path: "/dashboard/investor",
      component: () => import("@/views/pages/dashboard/InvestorDashboard.vue"),
      meta: {
        title: "InvestorDashboard",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: false,
      },
    },
    {
      name: "partner_dashboard",
      path: "/dashboard/partner",
      component: () => import("@/views/pages/dashboard/PartnerDashboard.vue"),
      meta: {
        title: "PartnerDashboard",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: true,
      },
    },
    {
      name: "banker_dashboard",
      path: "/dashboard/banker",
      component: () => import("@/views/pages/dashboard/BankerDashboard.vue"),
      meta: {
        title: "BankerDashboard",
        requiresAuth: true,
        requiresAdmin: false,
        requiresPartner: true,
      },
    },
    {
      name: "admin_dashboard",
      path: "/dashboard/admin",
      component: () => import("@/views/pages/dashboard/AdminDashboard.vue"),
      meta: {
        title: "AdminDashboard",
        requiresAuth: true,
        requiresAdmin: true,
        requiresPartner: false,
      },
    },
  ],
};

export default DashboardRoutes;
