<template>
  <v-container id="add-investor-modal" fluid tag="section">
    <v-card
      class="MobileScreen"
      style="width: 100% !important; max-width: 100% !important"
    >
      <v-card-title class="mb-2" style="padding-top: 0 !important">
        <v-row style="float: right">
          <v-btn color="black" small icon @click="$emit('close')">
            <v-icon small style="position: static">mdi-close-thick</v-icon>
          </v-btn>
        </v-row>
        <v-row align="center" justify="center" style="display: block">
          <h4
            class="dxa_modal_title text-center"
            style="color: var(--dark) !important; font-size: 20px"
          >
            {{ carousel + 1 }} - {{ $t(`onboarding_${carousel + 1}_title`) }}
          </h4>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-carousel height="360" hide-delimiters v-model="carousel">
          <v-carousel-item
            class="text-center"
            v-for="(slide, i) in slides"
            :key="i"
          >
            <v-img class="mx-auto" :src="gs.get_photo_path(slide)" />
          </v-carousel-item>
        </v-carousel>
        <v-item-group v-model="carousel" class="text-center" mandatory>
          <v-item
            v-for="n in length"
            :key="`btn-${n}`"
            v-slot="{ active, toggle }"
          >
            <v-btn :input-value="active" icon @click="toggle">
              <v-icon v-if="active" color="primary">mdi-record</v-icon>
              <v-icon v-else>mdi-record</v-icon>
            </v-btn>
          </v-item>
        </v-item-group>

        <p style="color: var(--dark); font-weight: bold">
          {{ $t(`onboarding_${carousel + 1}_text`) }}
        </p>
        <p style="color: var(--dark); font-weight: bold" v-if="carousel == 1">
          {{ $t(`onboarding_${carousel + 1}_text_2`) }}
        </p>
      </v-card-text>
    </v-card>

    <!-- TELADESKTOP -->
    <div style="display: flex; justify-content: center">
      <v-card
        class="DesktopScreen"
        style="width: 70% !important; max-width: 90% !important"
      >
        <v-card-title class="mb-2" style="padding-top: 0 !important">
          <v-row style="float: right">
            <v-btn color="black" small icon @click="$emit('close')">
              <v-icon small style="position: static">mdi-close-thick</v-icon>
            </v-btn>
          </v-row>
          <v-row align="center" justify="center" style="display: block">
            <h4
              class="dxa_modal_title text-center"
              style="color: var(--dark) !important; font-size: 20px"
            >
              {{ carousel + 1 }} - {{ $t(`onboarding_${carousel + 1}_title`) }}
            </h4>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-carousel height="545" hide-delimiters v-model="carousel">
            <v-carousel-item
              class="text-center"
              v-for="(slide, i) in slides"
              :key="i"
            >
              <v-img style="" class="my-auto" :src="gs.get_photo_path(slide)" />
            </v-carousel-item>
          </v-carousel>
          <v-item-group v-model="carousel" class="text-center" mandatory>
            <v-item
              v-for="n in length"
              :key="`btn-${n}`"
              v-slot="{ active, toggle }"
            >
              <v-btn :input-value="active" icon @click="toggle">
                <v-icon v-if="active" color="primary">mdi-record</v-icon>
                <v-icon v-else>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </v-item-group>

          <span style="color: var(--dark); font-weight: bold">
            {{ $t(`onboarding_${carousel + 1}_text`) }}
          </span>
          <span
            style="color: var(--dark); font-weight: bold"
            v-if="carousel == 1"
          >
            {{ $t(`onboarding_${carousel + 1}_text_2`) }}
          </span>
        </v-card-text>
      </v-card>
    </div>
    <!-- TELAMENORDESKTOP -->
    <div style="display: flex; justify-content: center">
      <v-card
        class="minorDesktopScreen"
        style="width: 70% !important; max-width: 90% !important"
      >
        <v-card-title class="mb-2" style="padding-top: 0 !important">
          <v-row style="float: right">
            <v-btn color="black" small icon @click="$emit('close')">
              <v-icon small style="position: static">mdi-close-thick</v-icon>
            </v-btn>
          </v-row>
          <v-row align="center" justify="center" style="display: block">
            <h4
              class="dxa_modal_title text-center"
              style="color: var(--dark) !important; font-size: 20px"
            >
              {{ carousel + 1 }} - {{ $t(`onboarding_${carousel + 1}_title`) }}
            </h4>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-carousel height="470" hide-delimiters v-model="carousel">
            <v-carousel-item
              class="text-center"
              v-for="(slide, i) in slides"
              :key="i"
            >
              <v-img style="" class="my-auto" :src="gs.get_photo_path(slide)" />
            </v-carousel-item>
          </v-carousel>
          <v-item-group v-model="carousel" class="text-center" mandatory>
            <v-item
              v-for="n in length"
              :key="`btn-${n}`"
              v-slot="{ active, toggle }"
            >
              <v-btn :input-value="active" icon @click="toggle">
                <v-icon v-if="active" color="primary">mdi-record</v-icon>
                <v-icon v-else>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </v-item-group>

          <span style="color: var(--dark); font-weight: bold">
            {{ $t(`onboarding_${carousel + 1}_text`) }}
          </span>
          <span
            style="color: var(--dark); font-weight: bold"
            v-if="carousel == 1"
          >
            {{ $t(`onboarding_${carousel + 1}_text_2`) }}
          </span>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>
<style scoped>
@media (max-width: 500px) {
  .DesktopScreen {
    display: none;
  }
  .minorDesktopScreen {
    display: none;
  }
}
@media (min-width: 500px) and (max-width: 700px) {
  .MobileScreen {
    display: none;
  }
  .DesktopScreen {
    display: none;
  }
}
@media (min-width: 700px) {
  .minorDesktopScreen {
    display: none;
  }
  .MobileScreen {
    display: none;
  }
}
</style>
<script>
import GeneralServices from "@/services/GeneralServices";

export default {
  name: "OnboardingModal",
  components: {},
  data: () => ({
    length: 3,

    gs: new GeneralServices(),
    carousel: 0,
    slides: [
      "Onboarding/01_2o_cadastro.gif",
      "Onboarding/02_ver_perfil.gif",
      "Onboarding/03_alocacao.gif",
      // 'Onboarding/03_alocacao_menor.gif',
    ],
  }),
  props: {},
  async created() {},
  methods: {},
};
</script>
