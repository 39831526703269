const AllocationsRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "AllocationsTable",
      path: "/",
      component: () => import("./table/AllocationsTable.vue"),
      meta: {
        title: "AllocationsTable",
        requiresAuth: true,
        requiresAdmin: true,
        requiresPartner: false,
      },
    },
    {
      name: "AllocationDetails",
      path: ":id",
      component: () => import("./AllocationDetails.vue"),
      meta: {
        title: "AllocationDetails",
        requiresAuth: true,
        requiresAdmin: true,
        requiresPartner: false,
      },
    },
  ],
};

export default AllocationsRoutes;
