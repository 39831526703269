

const RenewContractsRoutes = {
  component: () => import("@/components/LayoutIndex.vue"),
  children: [
    {
      name: "renewContracts",
      path: "/",
      component: () => import("@/views/pages/renewContract/RenewContracts.vue"),
      meta: {
        title: "RenewContracts",
        requiresAuth: true,
        requiresAdmin: false,
      },
    },
  ],
};

export default RenewContractsRoutes;
