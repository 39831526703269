import Vue from "vue";
import Vuex from "vuex";
import i18n from "./i18n";
import ApiService from "./services/ApiService";

const apiService = new ApiService();
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barImage:
      "https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg",
    drawer: null,
    user: null,
    token: null,
    signed_nda: null,
    lang: process.env.VUE_APP_I18N_LOCALE,
    changeImage: false,
    partnerColor: null,
    shouldSignContract: true,
    partner: null,
    step: 1,
    totalSteps: 2,
    round: null,
    currentRoundId: null,
    appBar: true,
    redirectUserRouter: null,
    isManagerUser: false,
  },
  mutations: {
    SET_APP_BAR(state, payload) {
      state.appBar = payload;
    },
    SET_IS_MANAGER_USER(state, payload) {
      state.isManagerUser = payload;
    },
    SET_REDIRECT_USER_ROUTER(state, payload) {
      state.redirectUserRouter = payload;
    },
    SET_ROUND_DATA(state, payload) {
      state.round = payload;
    },
    SET_CURRENT_ROUND_ID(state, payload) {
      state.currentRoundId = payload;
    },
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_PARTNER_COLOR(state, payload) {
      state.partnerColor = payload;
      localStorage.setItem("partnerColor", payload);
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_CHANGE_IMAGE(state, payload) {
      state.changeImage = payload;
    },
    SET_SCRIM(state, payload) {
      state.barColor = payload;
    },
    SET_LOGGED_USER(state, payload) {
      localStorage.removeItem("user");
      localStorage.setItem("user", payload);
      state.user = payload;
    },
    SET_EXPIRES_TOKEN(state, payload) {
      localStorage.removeItem("expires");
      localStorage.setItem("expires", payload);
      state.expires = payload;
    },
    SET_SIGNED_NDA(state, payload) {
      localStorage.removeItem("signed_nda");
      localStorage.setItem("signed_nda", payload);
      state.signed_nda = payload;
    },
    SET_RENEW_INVESTOR_SIGNATURE(state, payload) {
      state.shouldSignContract = payload;
    },
    SET_LANGUAGE(state, payload) {
      i18n.locale = payload;
      let url = `translation/general-fields/${payload}`;
      apiService
        .getRequest(url)
        .then((resp) => {
          let buff = Buffer.from(resp.message, "base64");
          i18n.mergeLocaleMessage(payload, JSON.parse(buff.toString()));
        })
        .catch((error) => {});
      localStorage.removeItem("lang");
      localStorage.setItem("lang", payload);
      state.lang = payload;
    },
    SET_CURRENT_TOKEN(state, payload) {
      localStorage.removeItem("token");
      localStorage.setItem("token", payload);
      let d = new Date("2021-12-17T03:24:00").toUTCString();
      document.cookie = "access_token=" + payload + ";" + d + ";path=/";
      // this.$cookies.set("access_token", payload, d);
      state.token = payload;
    },
    SET_PARTNER(state, payload) {
      state.partner = payload;
    },
    SET_STEP(state, payload) {
      state.step = payload;
    },
    SET_TOTAL_STEPS(state, payload) {
      state.totalSteps = payload;
    },
  },
  getters: {
    get_round_data: (state) => {
      return state.round;
    },
    get_is_manager_user: (state) => {
      return state.isManagerUser;
    },
    get_redirect_user_router: (state) => {
      return state.redirectUserRouter;
    },
    get_current_round_id: (state) => {
      return state.currentRoundId;
    },
    get_total_steps: (state) => {
      return state.totalSteps;
    },
    get_step: (state) => {
      return state.step;
    },
    get_partner: (state) => {
      return state.partner;
    },
    GET_RENEW_INVESTOR_SIGNATURE(state) {
      return state.shouldSignContract;
    },
    get_user: (state) => {
      return state.user;
    },
    get_language: (state) => {
      return state.lang;
    },
  },
  actions: {},
});
