import Vue from "vue";
import i18n from "./i18n";
import store from "./store";
import Router from "vue-router";
import ApiService from "./services/ApiService";
import GeneralServices from "./services/GeneralServices";
import AuthRoutes from "./views/pages/auth/routes";
import UserRoutes from "./views/pages/user/routes";
import CompanyRoutes from "./views/pages/company/routes";
import PipelineRoutes from "./views/pages/pipeline/routes";
import ProfileRoutes from "./views/pages/profile/routes";
import SettingsRoutes from "./views/pages/settings/routes";
import DashboardRoutes from "./views/pages/dashboard/routes";
import BankerRoutes from "./views/pages/banker/routes";
import FeedRoutes from "./views/pages/feed/routes";
import PageRoutes from "./views/pages/routes";
import StockRoutes from "./views/pages/stock/routes";
import SignatureNotificationRoutes from "./views/pages/signatureNotifications/routes";
import CategoryRoutes from "./views/pages/category/routes";
import CompanyInterestRoutes from "./views/pages/companyInterests/routes";
import AllocationsRoutes from "./views/pages/allocations/routes";
import FileDownloadLogRoutes from "./views/pages/fileLog/routes";
import ErrorLogRoutes from "./views/pages/errorLog/routes";
import BackgroundCheckRoutes from "./views/pages/backgroundCheck/routes";
import TranslationRoutes from "./views/pages/translation/routes";
import RecordEditRegister from "./views/pages/editionRecorder/routes";
import DashboardIndex from "@/components/LayoutIndex.vue";
import DashboardPipeline from "@/views/pages/pipeline/Pipeline.vue";
import LogExtratoRoutes from "./views/pages/logExtrato/routes";
import RenewContractsRoutes from "./views/pages/renewContract/routes";
import ThesisRoutes from "./views/pages/thesis/routes";
import AlphaManagerRoutes from "./views/pages/alphaManager/routes";
import moment from "moment";
import TCRoutes from "./views/pages/tc/router";
import VehicleRoutes from "./views/pages/vehicle/routes";
import PartnerB2bRoutes from "./views/pages/partnerB2b/routes";
import RoundRoutes from "./views/pages/round/routes";
import { UserTypeEnum } from "@/shared/enums/UserType";
import { accessRoutersVueApp } from "@/shared/enums/RedirectReactAppToVueAppEnum";
import MaterialsB2BRoutes from "@/views/pages/b2b/routes";
import UseTermsRoutes from "@/views/pages/useTerms/routes";
import ManagerRoutes from "@/views/pages/manager/routes";
import { LanguageTextEnum } from "@/shared/enums/LanguageEnum";
import { userAccessLog } from "./shared/helpers/userAccessLog";
import { getUserProfile } from "./shared/helpers/userProfile";

const apiService = new ApiService();
const gs = new GeneralServices();
Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/apphandler",
      component: () => import("@/views/pages/AppHandler.vue"),
      met: {
        requiresAuth: false,
      },
    },
    {
      path: "/app-redirect",
      component: () => import("@/views/pages/AppRedirectOtherPlatform.vue"),
      met: {
        requiresAuth: false,
      },
    },
    {
      path: "/auth",
      ...AuthRoutes,
    },
    {
      path: "/:partner_name/pipeline",
      component: DashboardIndex,
      children: [
        {
          path: "",
          name: "PipelineByPartner",
          component: DashboardPipeline,
          meta: {
            title: "PipelineByPartner",
            requiresAuth: true,
            requiresAdmin: false,
          },
        },
      ],
    },
    {
      path: "/tc",
      ...TCRoutes,
    },
    {
      path: "/users",
      ...UserRoutes,
    },
    {
      path: "/bankers",
      ...BankerRoutes,
    },
    {
      path: "/calendly",
      ...AlphaManagerRoutes,
    },
    {
      path: "/file-logs",
      ...FileDownloadLogRoutes,
    },
    {
      path: "/background-checks",
      ...BackgroundCheckRoutes,
    },
    {
      path: "/tc",
      ...TCRoutes,
    },
    {
      path: "/error-logs",
      ...ErrorLogRoutes,
    },
    {
      path: "/log-extrato",
      ...LogExtratoRoutes,
    },
    {
      path: "/record-edit-register",
      ...RecordEditRegister,
    },
    {
      path: "/translation",
      ...TranslationRoutes,
    },
    {
      path: "/companies",
      ...CompanyRoutes,
    },
    {
      path: "/theses",
      ...ThesisRoutes,
    },
    {
      path: "/categories",
      ...CategoryRoutes,
    },
    {
      path: "/requests",
      ...CompanyInterestRoutes,
    },
    {
      path: "/allocations",
      ...AllocationsRoutes,
    },
    {
      path: "/profile",
      ...ProfileRoutes,
    },
    {
      path: "/settings",
      ...SettingsRoutes,
    },
    {
      path: "/pipeline",
      ...PipelineRoutes,
    },
    {
      path: "/",
      redirect: "/dashboard",
      ...DashboardRoutes,
    },
    {
      path: "/feed",
      ...FeedRoutes,
    },
    {
      path: "/stock",
      ...StockRoutes,
    },
    {
      path: "/signature-notifications",
      ...SignatureNotificationRoutes,
    },
    {
      path: "/renewContracts",
      ...RenewContractsRoutes,
    },
    {
      path: "/vehicle",
      ...VehicleRoutes,
    },
    {
      path: "/use-terms",
      ...UseTermsRoutes,
    },
    {
      path: "/partnerb2b",
      ...PartnerB2bRoutes,
    },
    {
      name: "round",
      path: "/round",
      ...RoundRoutes,
    },
    {
      name: "materialsB2B",
      path: "/b2b",
      ...MaterialsB2BRoutes,
    },
    {
      name: "manager",
      path: "/manager",
      ...ManagerRoutes,
    },
    {
      path: "*",
      ...PageRoutes,
    },
  ],
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  mode: "history",
  base: process.env.BASE_URL,
});
router.beforeEach(async (to, from, next) => {
  let token = localStorage.getItem("token");
  let expiresToken = localStorage.getItem("expires");
  let lang = localStorage.getItem("lang") || "en";
  store.commit("SET_LANGUAGE", lang);
  const loginRequiredMessage =
    i18n.locale === LanguageTextEnum.PT
      ? "Faça login para continuar"
      : "Login to continue";
  const userIsLogged =
    to.meta.requiresAuth && token && moment().isBefore(moment(expiresToken));
  let userProfile = JSON.parse(localStorage.getItem("user"));

  // if para verificar se o usuário está logado
  if (userIsLogged) {
    let payload = {
      routerPath: to.path,
      domain: window.location.origin,
    };
    // chamando o method de log, somente quando ele estiver logado, pois o back espera sempre um usuário logado.
    userAccessLog(payload);

    const userProfileData = await getUserProfile(true);

    if (userProfileData) {
      userProfile = userProfileData;
    }
  }

  // caminho das rotas da aplicação
  const routesPath = {
    companies: "/companies",
    login: "/login",
    auth: "/auth",
    settings: "/settings",
    renewContracts: "/renewContracts",
    dashboard: "/dashboard",
    pipeline: "/pipeline",
    partnerDashboard: "/dashboard/partner",
    translation: "/translation",
    bankerDashboard: "/dashboard/banker",
    alphaInvestors: "/alpha-investors",
    round: "/round",
    appRedirect: "/app-redirect",
    useTerms: "/use-terms",
  };

  // save routes history > from to current
  // history.current.meta.from
  if (to.meta) {
    to.meta.from = from.path;
  }

  const handleRequiredLogin = () => {
    next("/auth");
    Vue.$toast.warning(loginRequiredMessage);
    localStorage.clear();
  };

  if (!moment().isBefore(moment(expiresToken))) {
    token = null;
  }
  if (to.path == routesPath.dashboard && !token) {
    next({
      path: routesPath.auth,
    });
  }
  if (to.path == routesPath.round && process.env.VUE_APP_ROUNDS !== "TRUE") {
    next({
      path: routesPath.dashboard,
    });
  }

  let userType = UserTypeEnum.Investor;
  if (userProfile !== null) {
    userType = userProfile.type;
  }

  // pegando o usuário que está logando
  const users = {
    investor: userType === UserTypeEnum.Investor,
    admin: userType === UserTypeEnum.Admin,
    partner: userType === UserTypeEnum.Partner,
    translator: userType === UserTypeEnum.Translator,
    financialAnalyst: userType === UserTypeEnum.FinancialAnalyst,
    contentAnalyst: userType === UserTypeEnum.ContentAnalyst,
    banker: userType === UserTypeEnum.Banker,
    alphaAnalyst: userType === UserTypeEnum.AlphaAnalyst,
    managerUser: userType === UserTypeEnum.ManagerUser,
  };

  //precisamos criar uma forma de validar a rota do tipo do usuário e o seu mapa definido no routes.js
  if (
    to.path == routesPath.companies &&
    token &&
    (users.admin ||
      users.financialAnalyst ||
      users.contentAnalyst ||
      users.alphaAnalyst)
  ) {
    next();
  }

  // só faz o onboarded no vue, se ele estiver sem acesso a aplicação banker (redirectToReactApp = false)
  // se a prop for true, ele faz o onboarded no react.
  if (
    userProfile &&
    users.banker &&
    !userProfile.isOnboarded &&
    to.name != "BankerOnboarding" &&
    !to.path.includes(routesPath.auth) &&
    !userProfile.redirectToReactApp
  ) {
    let partnerName;

    // mantendo a funcionalidade do next, caso o userProfile seja = user (do localStorage).
    if (userProfile.partnerB2b) {
      partnerName = userProfile.partnerB2b.name;
    } else {
      partnerName = userProfile.partnerName;
    }

    next({
      name: "BankerOnboarding",
      query: {
        user: userProfile.id,
        partner: partnerName,
      },
    });
    return;
  }
  if (
    userProfile &&
    userProfile.mustRenewPassword &&
    to.name != "Settings" &&
    !to.path.includes(routesPath.auth)
  ) {
    if (
      // Só cai direto na redefinição de senha, os usuários que não tem aplicação no react. Banker, manager e Partner tem.
      (!users.banker && !users.partner && !users.managerUser) ||
      // Agora, se o usuário for um banker ou um partner e não tiver acesso a aplicação react (redirectToReactApp = false), ele fica no vue.
      // e faz o reset de senha
      // o manager não tem ambiente para o vue, por isso ele sempre vai para o react.
      ((users.banker || users.partner) && !userProfile.redirectToReactApp)
    ) {
      next({
        path: routesPath.settings,
        query: {
          firstLogin: true,
        },
      });
      return;
    }
  }

  if (
    userProfile &&
    !userProfile.mustRenewPassword &&
    !userProfile.useTerms &&
    to.name !== "UseTerms" &&
    !to.path.includes(routesPath.auth)
  ) {
    if (users.investor) {
      next({
        path: routesPath.useTerms,
      });
      return;
    }
  }

  // caso algum usuário tente acessar a rota use-terms quando useTerms = true, ele toma um redirect para o profile.
  if (userProfile && userProfile.useTerms && to.name === "UseTerms") {
    next({
      path: routesPath.settings,
    });
    return;
  }

  const signContractLater = localStorage.getItem("signContractLater");
  if (
    userProfile &&
    to.name != "renewContracts" &&
    to.name != "LoginClient" &&
    signContractLater &&
    signContractLater == "false" &&
    (userProfile.type !== UserTypeEnum.Banker ||
      // quando for banker, só assina o termo pelo vue, se não tiver acesso a aplicação banker, redirectToReactApp = false.
      (userProfile.type === UserTypeEnum.Banker &&
        userProfile.isOnboarded === true &&
        !userProfile.redirectToReactApp)) &&
    (userProfile.type !== UserTypeEnum.Banker ||
      (userProfile.type === UserTypeEnum.Banker &&
        to.path !== routesPath.settings &&
        !userProfile.redirectToReactApp)) &&
    (userProfile.aYearWithoutInvestments ||
      (userProfile.type === UserTypeEnum.Banker &&
        !userProfile.redirectToReactApp))
  ) {
    next({
      path: routesPath.renewContracts,
    });
  }
  if (to.path == routesPath.dashboard && !token) {
    next(routesPath.auth);
  }
  if ((to.name === "login" || to.path == routesPath.dashboard) && token) {
    const reactApp = localStorage.getItem("reactApp");
    const accessVueNavigation =
      localStorage.getItem("accessVueNavigation") === "false";

    // SÓ LIMPA O LOCALSTORAGE PARA FORÇAR O LOGIN, QUANDO VIER DA APLICAÇÃO REACT E NÃO TIVER VISÍVEL O DRAWER E O NAVBAR
    // SE TIVER VISÍVEL UM DESSES DOIS, NÃO TEM PROBLEMA ABRIR OUTRA JANELA DURANTE O REDIRECT E MOSTRAR O NAVBAR/DRAWER
    // O CONTROLE DELES VAI SER FEITO PELA PROP NO LOCALSTORAGE

    // 1 - o manager tem acesso a todas as rotas mesmo com o NAVBAR/DRAWER = false, então pulamos essa regra para o manager.
    if (
      reactApp &&
      reactApp === "true" &&
      accessVueNavigation &&
      !users.managerUser
    ) {
      localStorage.clear();
    }

    if (users.investor || users.financialAnalyst) {
      next(routesPath.pipeline);
    } else if (users.admin || users.contentAnalyst) {
      next(routesPath.companies);
    } else if (users.partner) {
      next(routesPath.partnerDashboard);
    } else if (users.translator) {
      next(routesPath.translation);
    } else if (users.banker) {
      next(routesPath.bankerDashboard);
    } else if (users.alphaAnalyst) {
      next(routesPath.alphaInvestors);
    } else if (users.managerUser) {
      next(routesPath.round);
    }
  } else {
    if (to.meta.requiresAuth) {
      if (token) {
        if (moment().isBefore(moment(expiresToken))) {
          const fromReactApp = localStorage.getItem("reactApp") === "true";
          const accessVueRouterPermissions = JSON.parse(
            localStorage.getItem("accessVueRouterPermissions")
          );
          const routerToPath = localStorage.getItem("routerToPath");

          // Só damos o redirect pelo router, quando o usuário não vem pelo react.
          // Dessa forma permitimos a navegação do usuário que veio do react na aplicação vue.
          // Esse redirect não permite o usuário acessar a aplicação vue, por isso só usamos quando ele faz o login na aplicação vue e
          // não quando vem do react. Se veio do react, vamos dar acesso a alguma tela do vue.
          if (!fromReactApp) {
            const redirectReact = () => {
              // se for banker ou partner tem acesso ao react
              const userCanAccessReact =
                users.banker || users.partner || users.managerUser;

              if (
                userCanAccessReact &&
                to.path !== routesPath.appRedirect &&
                userProfile.redirectToReactApp
              ) {
                next(routesPath.appRedirect);
              }
            };

            redirectReact();
          }

          // caso seja para ver uma página somente.
          // qualquer rota diferente dessa página, ele volta para o login.
          if (accessVueRouterPermissions && accessVueRouterPermissions.access) {
            if (
              accessVueRouterPermissions.access ===
              accessRoutersVueApp.ONLY_PAGE
            ) {
              if (to.path !== routerToPath) {
                handleRequiredLogin();
              }
            }

            // quando queremos dar acesso a mais de uma página.
            // Mas não todas, somente aquelas definidas no react
            if (
              accessVueRouterPermissions.access ===
                accessRoutersVueApp.PRIVATE_PAGES &&
              accessVueRouterPermissions.routes &&
              accessVueRouterPermissions.routes.length > 0
            ) {
              const findRouter = accessVueRouterPermissions.routes.find(
                (router) => router === to.name
              );

              if (!findRouter) {
                handleRequiredLogin();
              }
            }
          }

          if (to.name === "Pipeline" && users.banker) {
            next(routesPath.bankerDashboard);
          }

          if (
            (to.name == "Pipeline" || to.name == "PipelineByPartner") &&
            users.partner
          ) {
            next(routesPath.partnerDashboard);
          }
          if (to.meta.requiredContentAnalyst && users.contentAnalyst) {
            next();
          } else if (
            to.meta.requiresFinancialAnalyst &&
            users.financialAnalyst
          ) {
            next();
          } else if (
            (to.meta.requiresAdmin && users.admin) ||
            !to.meta.requiresAdmin
          ) {
            next();
          } else {
            if (users.investor || users.financialAnalyst) {
              next(routesPath.pipeline);
            } else if (
              users.admin ||
              users.contentAnalyst ||
              users.alphaAnalyst
            ) {
              next(routesPath.alphaInvestors);
            } else if (users.partner) {
              next(routesPath.partnerDashboard);
            } else if (users.translator) {
              next(routesPath.translation);
            } else if (users.banker) {
              next(routesPath.bankerDashboard);
            } else if (users.alphaAnalyst) {
              next(routesPath.alphaInvestors);
            }
          }
        } else {
          next(routesPath.auth);
        }
      } else {
        if (to.name === "companyBriefing") {
          next({
            path: `/auth/register-client?partner=true&partner_name=${
              to.params.partner_name
            }${
              to.query.partner_login
                ? "&partner_login=" + to.query.partner_login
                : ""
            }`,
          });
        } else if (to.name === "PipelineByPartner") {
          next({
            path: `/auth?partner_name=${to.params.partner_name}${
              to.query.partner_login
                ? "&partner_login=" + to.query.partner_login
                : ""
            }`,
          });
        } else {
          store.commit("SET_REDIRECT_USER_ROUTER", to.path);
          next(routesPath.auth);
        }
      }
    } else {
      if (
        (to.name == "Pipeline" || to.name == "PipelineByPartner") &&
        users.partner
      ) {
        next(routesPath.partnerDashboard);
      }

      if (to.name === "Pipeline" && users.banker) {
        next(routesPath.bankerDashboard);
      }

      if (to.path === routesPath.pipeline && !token) {
        store.commit("SET_REDIRECT_USER_ROUTER", to.path);
        next(routesPath.auth);
      }
      next();
    }
  }
});

export default router;
